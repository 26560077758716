import * as theme from '../../styles/theme'
import React from 'react'
import { css } from '@emotion/react'

export type ScrollerSection =
  | 'modular'
  | 'platforms'
  | 'full-stack'
  | 'integrations'
  | 'custom'
  | null

interface Props {
  section: ScrollerSection
}

export default function Scroller({ section }: Props) {
  return (
    <div css={styles.scroller}>
      <div css={styles.content}>
        <svg
          css={styles.svg}
          className={section || ''}
          viewBox="0 0 636 636"
          xmlns="http://www.w3.org/2000/svg">
          <path
            className="cloud-background"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338 1.26213C376.792 -3.40511 414.106 14.1147 452.221 22.7084C494.562 32.2549 543.293 28.0558 577.171 55.1875C610.826 82.1404 635.138 126.322 635.439 169.439C635.763 215.81 590.349 250.304 578.849 295.228C571.248 324.915 578.27 355.772 579.902 386.373C582.089 427.351 609.216 471.634 590.201 507.999C572.366 542.106 520.855 539.779 488.658 560.868C455.662 582.48 436.2 628.3 397.229 634.393C357.934 640.536 325.44 601.062 286.611 592.455C249.114 584.143 206.853 602.062 172.642 584.608C138.623 567.253 124.475 526.593 100.017 497.262C74.6221 466.809 41.0805 442.738 24.356 406.785C6.9921 369.458 -3.75913 327.177 1.92896 286.404C7.59246 245.807 32.1676 210.393 56.3691 177.31C78.9328 146.466 107.936 121.54 138.91 99.1548C168.197 77.9894 201.527 64.9133 233.953 48.9693C268.486 31.9893 299.794 5.85895 338 1.26213Z"
            fill="#D6EAFF"
          />
          <path
            className="line custom-line custom-wake-word-line"
            d="M160.388 171.588L92.92 193.486L160.374 171.546"
            stroke="#3AB795"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="custom custom-wake-word">
            <rect
              x="63.92"
              y="164.49"
              width="58"
              height="58"
              rx="29"
              fill="black"
            />
            <path
              d="M78.1378 194.122C78.0825 194.887 77.7993 195.489 77.2882 195.929C76.7804 196.368 76.1098 196.588 75.2765 196.588C74.365 196.588 73.6473 196.282 73.1232 195.67C72.6023 195.055 72.3419 194.212 72.3419 193.141V192.706C72.3419 192.022 72.4624 191.42 72.7032 190.899C72.9441 190.379 73.2876 189.98 73.7335 189.703C74.1827 189.423 74.7036 189.283 75.296 189.283C76.1163 189.283 76.7771 189.503 77.2784 189.942C77.7797 190.382 78.0695 190.999 78.1476 191.793H76.6827C76.6469 191.334 76.5184 191.002 76.297 190.797C76.0789 190.589 75.7452 190.484 75.296 190.484C74.8077 190.484 74.4415 190.66 74.1974 191.012C73.9565 191.36 73.8328 191.902 73.8263 192.638V193.175C73.8263 193.943 73.9419 194.505 74.173 194.859C74.4073 195.214 74.7752 195.392 75.2765 195.392C75.729 195.392 76.0659 195.289 76.2872 195.084C76.5118 194.876 76.6404 194.555 76.673 194.122H78.1378Z"
              fill="#3AB795"
            />
            <path
              d="M84.6806 189.381V194.063C84.6806 194.841 84.4365 195.457 83.9482 195.909C83.4631 196.362 82.7991 196.588 81.956 196.588C81.1259 196.588 80.4667 196.368 79.9784 195.929C79.4902 195.489 79.2411 194.885 79.2314 194.117V189.381H80.6962V194.073C80.6962 194.539 80.8069 194.879 81.0282 195.094C81.2529 195.305 81.5621 195.411 81.956 195.411C82.7795 195.411 83.1978 194.978 83.2109 194.112V189.381H84.6806Z"
              fill="#3AB795"
            />
            <path
              d="M89.7683 194.625C89.7683 194.348 89.6706 194.137 89.4753 193.99C89.28 193.84 88.9284 193.684 88.4206 193.521C87.9128 193.355 87.5108 193.193 87.2146 193.033C86.4073 192.597 86.0036 192.009 86.0036 191.271C86.0036 190.886 86.1111 190.545 86.3259 190.245C86.544 189.942 86.8549 189.706 87.2585 189.537C87.6654 189.368 88.1212 189.283 88.6257 189.283C89.1335 189.283 89.586 189.376 89.9831 189.562C90.3803 189.744 90.6879 190.003 90.906 190.338C91.1273 190.673 91.238 191.054 91.238 191.48H89.7732C89.7732 191.155 89.6706 190.903 89.4656 190.724C89.2605 190.541 88.9724 190.45 88.6013 190.45C88.2432 190.45 87.9649 190.527 87.7663 190.68C87.5678 190.829 87.4685 191.028 87.4685 191.275C87.4685 191.507 87.584 191.7 87.8152 191.856C88.0495 192.013 88.393 192.159 88.8454 192.296C89.6788 192.547 90.2859 192.857 90.6667 193.229C91.0476 193.6 91.238 194.062 91.238 194.615C91.238 195.23 91.0053 195.714 90.5398 196.065C90.0743 196.414 89.4476 196.588 88.6599 196.588C88.113 196.588 87.615 196.489 87.1657 196.29C86.7165 196.088 86.3731 195.813 86.1355 195.465C85.9011 195.117 85.7839 194.713 85.7839 194.254H87.2536C87.2536 195.038 87.7224 195.431 88.6599 195.431C89.0082 195.431 89.28 195.361 89.4753 195.221C89.6706 195.077 89.7683 194.879 89.7683 194.625Z"
              fill="#3AB795"
            />
            <path
              d="M97.7857 190.567H95.6079V196.49H94.1431V190.567H91.9947V189.381H97.7857V190.567Z"
              fill="#3AB795"
            />
            <path
              d="M104.529 193.097C104.529 193.797 104.405 194.41 104.158 194.938C103.91 195.465 103.555 195.872 103.093 196.158C102.634 196.445 102.107 196.588 101.511 196.588C100.922 196.588 100.396 196.446 99.9339 196.163C99.4717 195.88 99.1136 195.476 98.8597 194.952C98.6058 194.425 98.4772 193.819 98.4739 193.136V192.784C98.4739 192.084 98.5993 191.469 98.8499 190.938C99.1038 190.405 99.4603 189.996 99.9193 189.713C100.382 189.426 100.909 189.283 101.501 189.283C102.094 189.283 102.619 189.426 103.078 189.713C103.541 189.996 103.897 190.405 104.148 190.938C104.402 191.469 104.529 192.083 104.529 192.779V193.097ZM103.044 192.774C103.044 192.029 102.911 191.463 102.644 191.075C102.377 190.688 101.996 190.494 101.501 190.494C101.01 190.494 100.631 190.686 100.364 191.07C100.097 191.451 99.9616 192.011 99.9583 192.75V193.097C99.9583 193.823 100.092 194.386 100.359 194.786C100.626 195.187 101.01 195.387 101.511 195.387C102.003 195.387 102.38 195.195 102.644 194.811C102.908 194.423 103.041 193.86 103.044 193.121V192.774Z"
              fill="#3AB795"
            />
            <path
              d="M107.707 189.381L109.533 194.537L111.35 189.381H113.274V196.49H111.804V194.547L111.95 191.192L110.031 196.49H109.026L107.111 191.197L107.258 194.547V196.49H105.793V189.381H107.707Z"
              fill="#3AB795"
            />
            <rect
              x="63.92"
              y="164.49"
              width="58"
              height="58"
              rx="29"
              stroke="#3AB795"
              strokeWidth="2"
            />
          </g>
          <path
            className="line custom-line custom-keyword-line"
            d="M227.943 122.504L186.267 65.1055L227.978 122.478"
            stroke="#FF6666"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="custom custom-keyword">
            <rect
              x="157.27"
              y="30.1099"
              width="58"
              height="58"
              rx="29"
              fill="black"
            />
            <path
              d="M171.488 59.7417C171.432 60.5067 171.149 61.1089 170.638 61.5483C170.13 61.9878 169.46 62.2075 168.626 62.2075C167.715 62.2075 166.997 61.9015 166.473 61.2896C165.952 60.6743 165.692 59.8312 165.692 58.7603V58.3257C165.692 57.6421 165.812 57.0399 166.053 56.519C166.294 55.9982 166.638 55.5994 167.083 55.3228C167.533 55.0428 168.054 54.9028 168.646 54.9028C169.466 54.9028 170.127 55.1226 170.628 55.562C171.13 56.0015 171.419 56.6183 171.498 57.4126H170.033C169.997 56.9536 169.868 56.6216 169.647 56.4165C169.429 56.2082 169.095 56.104 168.646 56.104C168.158 56.104 167.792 56.2798 167.547 56.6313C167.306 56.9797 167.183 57.5216 167.176 58.2573V58.7944C167.176 59.5627 167.292 60.1242 167.523 60.479C167.757 60.8338 168.125 61.0112 168.626 61.0112C169.079 61.0112 169.416 60.9087 169.637 60.7036C169.862 60.4953 169.99 60.1746 170.023 59.7417H171.488Z"
              fill="#FF6666"
            />
            <path
              d="M178.031 55.0005V59.6831C178.031 60.4611 177.786 61.0763 177.298 61.5288C176.813 61.9813 176.149 62.2075 175.306 62.2075C174.476 62.2075 173.817 61.9878 173.328 61.5483C172.84 61.1089 172.591 60.505 172.581 59.7368V55.0005H174.046V59.6929C174.046 60.1584 174.157 60.4985 174.378 60.7134C174.603 60.925 174.912 61.0308 175.306 61.0308C176.13 61.0308 176.548 60.5978 176.561 59.7319V55.0005H178.031Z"
              fill="#FF6666"
            />
            <path
              d="M183.118 60.2446C183.118 59.9679 183.021 59.7563 182.825 59.6099C182.63 59.4601 182.278 59.3039 181.771 59.1411C181.263 58.9751 180.861 58.8123 180.565 58.6528C179.757 58.2166 179.354 57.6291 179.354 56.8901C179.354 56.506 179.461 56.1642 179.676 55.8647C179.894 55.562 180.205 55.326 180.608 55.1567C181.015 54.9875 181.471 54.9028 181.976 54.9028C182.483 54.9028 182.936 54.9956 183.333 55.1812C183.73 55.3634 184.038 55.6222 184.256 55.9575C184.477 56.2928 184.588 56.6737 184.588 57.1001H183.123C183.123 56.7746 183.021 56.5223 182.816 56.3433C182.61 56.161 182.322 56.0698 181.951 56.0698C181.593 56.0698 181.315 56.1463 181.116 56.2993C180.918 56.4491 180.818 56.6476 180.818 56.895C180.818 57.1261 180.934 57.3198 181.165 57.4761C181.4 57.6323 181.743 57.7788 182.195 57.9155C183.029 58.1662 183.636 58.4771 184.017 58.8481C184.398 59.2192 184.588 59.6815 184.588 60.2349C184.588 60.8501 184.355 61.3335 183.89 61.6851C183.424 62.0334 182.798 62.2075 182.01 62.2075C181.463 62.2075 180.965 62.1082 180.516 61.9097C180.067 61.7078 179.723 61.4328 179.485 61.0845C179.251 60.7362 179.134 60.3325 179.134 59.8735H180.604C180.604 60.658 181.072 61.0503 182.01 61.0503C182.358 61.0503 182.63 60.9803 182.825 60.8403C183.021 60.6971 183.118 60.4985 183.118 60.2446Z"
              fill="#FF6666"
            />
            <path
              d="M191.136 56.187H188.958V62.1099H187.493V56.187H185.345V55.0005H191.136V56.187Z"
              fill="#FF6666"
            />
            <path
              d="M197.879 58.7163C197.879 59.4162 197.755 60.0298 197.508 60.5571C197.26 61.0845 196.905 61.4914 196.443 61.7778C195.984 62.0643 195.457 62.2075 194.861 62.2075C194.272 62.2075 193.746 62.0659 193.284 61.7827C192.822 61.4995 192.464 61.0959 192.21 60.5718C191.956 60.0444 191.827 59.439 191.824 58.7554V58.4038C191.824 57.7039 191.949 57.0887 192.2 56.5581C192.454 56.0243 192.81 55.6157 193.269 55.3325C193.731 55.0461 194.259 54.9028 194.851 54.9028C195.444 54.9028 195.969 55.0461 196.428 55.3325C196.891 55.6157 197.247 56.0243 197.498 56.5581C197.752 57.0887 197.879 57.7023 197.879 58.3989V58.7163ZM196.394 58.394C196.394 57.6486 196.261 57.0822 195.994 56.6948C195.727 56.3075 195.346 56.1138 194.851 56.1138C194.36 56.1138 193.981 56.3058 193.714 56.6899C193.447 57.0708 193.312 57.6307 193.308 58.3696V58.7163C193.308 59.4422 193.442 60.0054 193.709 60.4058C193.976 60.8062 194.36 61.0063 194.861 61.0063C195.353 61.0063 195.73 60.8143 195.994 60.4302C196.258 60.0428 196.391 59.4797 196.394 58.7407V58.394Z"
              fill="#FF6666"
            />
            <path
              d="M201.057 55.0005L202.883 60.1567L204.7 55.0005H206.624V62.1099H205.154V60.1665L205.3 56.812L203.381 62.1099H202.375L200.461 56.8169L200.608 60.1665V62.1099H199.143V55.0005H201.057Z"
              fill="#FF6666"
            />
            <rect
              x="157.27"
              y="30.1099"
              width="58"
              height="58"
              rx="29"
              stroke="#FF6666"
              strokeWidth="2"
            />
          </g>
          <path
            className="line custom-line"
            d="M408.907 190.478L341.405 97.5088L408.965 190.436"
            stroke="#FACC5F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="custom custom-tts">
            <rect
              x="312.4"
              y="68.5098"
              width="58"
              height="58"
              rx="29"
              fill="black"
            />
            <path
              d="M326.618 98.1416C326.562 98.9066 326.279 99.5088 325.768 99.9482C325.26 100.388 324.59 100.607 323.756 100.607C322.845 100.607 322.127 100.301 321.603 99.6895C321.082 99.0742 320.822 98.2311 320.822 97.1602V96.7256C320.822 96.042 320.942 95.4398 321.183 94.9189C321.424 94.3981 321.768 93.9993 322.214 93.7227C322.663 93.4427 323.184 93.3027 323.776 93.3027C324.596 93.3027 325.257 93.5225 325.758 93.9619C326.26 94.4014 326.549 95.0182 326.628 95.8125H325.163C325.127 95.3535 324.998 95.0215 324.777 94.8164C324.559 94.6081 324.225 94.5039 323.776 94.5039C323.288 94.5039 322.922 94.6797 322.677 95.0312C322.436 95.3796 322.313 95.9215 322.306 96.6572V97.1943C322.306 97.9626 322.422 98.5241 322.653 98.8789C322.887 99.2337 323.255 99.4111 323.756 99.4111C324.209 99.4111 324.546 99.3086 324.767 99.1035C324.992 98.8952 325.12 98.5745 325.153 98.1416H326.618Z"
              fill="#FACC5F"
            />
            <path
              d="M333.161 93.4004V98.083C333.161 98.861 332.916 99.4762 332.428 99.9287C331.943 100.381 331.279 100.607 330.436 100.607C329.606 100.607 328.947 100.388 328.458 99.9482C327.97 99.5088 327.721 98.9049 327.711 98.1367V93.4004H329.176V98.0928C329.176 98.5583 329.287 98.8984 329.508 99.1133C329.733 99.3249 330.042 99.4307 330.436 99.4307C331.26 99.4307 331.678 98.9977 331.691 98.1318V93.4004H333.161Z"
              fill="#FACC5F"
            />
            <path
              d="M338.248 98.6445C338.248 98.3678 338.151 98.1562 337.955 98.0098C337.76 97.86 337.408 97.7038 336.901 97.541C336.393 97.375 335.991 97.2122 335.695 97.0527C334.887 96.6165 334.484 96.029 334.484 95.29C334.484 94.9059 334.591 94.5641 334.806 94.2646C335.024 93.9619 335.335 93.7259 335.739 93.5566C336.145 93.3874 336.601 93.3027 337.106 93.3027C337.614 93.3027 338.066 93.3955 338.463 93.5811C338.86 93.7633 339.168 94.0221 339.386 94.3574C339.607 94.6927 339.718 95.0736 339.718 95.5H338.253C338.253 95.1745 338.151 94.9222 337.946 94.7432C337.74 94.5609 337.452 94.4697 337.081 94.4697C336.723 94.4697 336.445 94.5462 336.246 94.6992C336.048 94.849 335.948 95.0475 335.948 95.2949C335.948 95.526 336.064 95.7197 336.295 95.876C336.53 96.0322 336.873 96.1787 337.325 96.3154C338.159 96.5661 338.766 96.877 339.147 97.248C339.528 97.6191 339.718 98.0814 339.718 98.6348C339.718 99.25 339.485 99.7334 339.02 100.085C338.554 100.433 337.928 100.607 337.14 100.607C336.593 100.607 336.095 100.508 335.646 100.31C335.197 100.108 334.853 99.8327 334.615 99.4844C334.381 99.1361 334.264 98.7324 334.264 98.2734H335.734C335.734 99.0579 336.202 99.4502 337.14 99.4502C337.488 99.4502 337.76 99.3802 337.955 99.2402C338.151 99.097 338.248 98.8984 338.248 98.6445Z"
              fill="#FACC5F"
            />
            <path
              d="M346.266 94.5869H344.088V100.51H342.623V94.5869H340.475V93.4004H346.266V94.5869Z"
              fill="#FACC5F"
            />
            <path
              d="M353.009 97.1162C353.009 97.8161 352.885 98.4297 352.638 98.957C352.39 99.4844 352.035 99.8913 351.573 100.178C351.114 100.464 350.587 100.607 349.991 100.607C349.402 100.607 348.876 100.466 348.414 100.183C347.952 99.8994 347.594 99.4958 347.34 98.9717C347.086 98.4443 346.957 97.8389 346.954 97.1553V96.8037C346.954 96.1038 347.079 95.4886 347.33 94.958C347.584 94.4242 347.94 94.0156 348.399 93.7324C348.861 93.446 349.389 93.3027 349.981 93.3027C350.574 93.3027 351.099 93.446 351.558 93.7324C352.021 94.0156 352.377 94.4242 352.628 94.958C352.882 95.4886 353.009 96.1022 353.009 96.7988V97.1162ZM351.524 96.7939C351.524 96.0485 351.391 95.4821 351.124 95.0947C350.857 94.7074 350.476 94.5137 349.981 94.5137C349.49 94.5137 349.111 94.7057 348.844 95.0898C348.577 95.4707 348.442 96.0306 348.438 96.7695V97.1162C348.438 97.8421 348.572 98.4053 348.839 98.8057C349.106 99.2061 349.49 99.4062 349.991 99.4062C350.483 99.4062 350.86 99.2142 351.124 98.8301C351.388 98.4427 351.521 97.8796 351.524 97.1406V96.7939Z"
              fill="#FACC5F"
            />
            <path
              d="M356.187 93.4004L358.013 98.5566L359.83 93.4004H361.754V100.51H360.284V98.5664L360.43 95.2119L358.511 100.51H357.505L355.591 95.2168L355.738 98.5664V100.51H354.273V93.4004H356.187Z"
              fill="#FACC5F"
            />
            <rect
              x="312.4"
              y="68.5098"
              width="58"
              height="58"
              rx="29"
              stroke="#FACC5F"
              strokeWidth="2"
            />
          </g>
          <path
            className="line integration-line wake-word-line"
            d="M160.388 171.504L118.712 114.105L160.423 171.478"
            stroke="#3AB795"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="integration wake-word-spokestack">
            <path
              d="M88.71 114.11C88.71 97.5413 102.141 84.1099 118.71 84.1099C135.279 84.1099 148.71 97.5413 148.71 114.11C148.71 130.678 135.279 144.11 118.71 144.11C102.141 144.11 88.71 130.678 88.71 114.11Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M117.281 123.407C116.501 123.407 115.769 123.198 115.139 122.833C114.508 123.198 113.776 123.407 112.996 123.407C110.629 123.407 108.71 121.486 108.71 119.116V111.964C108.71 110.384 109.989 109.104 111.567 109.104C113.145 109.104 114.424 110.384 114.424 111.964V119.116C114.424 119.842 114.694 120.504 115.139 121.008C115.583 120.504 115.853 119.842 115.853 119.116V109.104C115.853 106.734 117.772 104.812 120.139 104.812C120.919 104.812 121.651 105.021 122.281 105.387C122.912 105.021 123.644 104.812 124.424 104.812C126.791 104.812 128.71 106.734 128.71 109.104V116.255C128.71 117.835 127.431 119.116 125.853 119.116C124.275 119.116 122.996 117.835 122.996 116.255V109.104C122.996 108.378 122.726 107.716 122.281 107.211C121.837 107.716 121.567 108.378 121.567 109.104V119.116C121.567 121.486 119.648 123.407 117.281 123.407ZM110.139 111.964V119.116C110.139 120.696 111.418 121.977 112.996 121.977C113.33 121.977 113.65 121.919 113.948 121.814C113.353 121.077 112.996 120.138 112.996 119.116L112.996 111.964C112.996 111.174 112.356 110.534 111.567 110.534C110.778 110.534 110.139 111.174 110.139 111.964ZM124.424 109.104C124.424 108.081 124.067 107.143 123.471 106.406C123.769 106.3 124.09 106.243 124.424 106.243C126.002 106.243 127.281 107.524 127.281 109.104V116.255C127.281 117.045 126.642 117.686 125.853 117.686C125.064 117.686 124.424 117.045 124.424 116.255L124.424 109.104ZM120.139 106.243C120.473 106.243 120.793 106.3 121.091 106.406C120.495 107.143 120.139 108.081 120.139 109.104V119.116C120.139 120.696 118.859 121.977 117.281 121.977C116.947 121.977 116.627 121.919 116.329 121.814C116.924 121.077 117.281 120.138 117.281 119.116V109.104C117.281 107.524 118.561 106.243 120.139 106.243Z"
              fill="#61FAE9"
            />
            <path
              d="M118.71 142.11C103.246 142.11 90.71 129.574 90.71 114.11H86.71C86.71 131.783 101.037 146.11 118.71 146.11V142.11ZM146.71 114.11C146.71 129.574 134.174 142.11 118.71 142.11V146.11C136.383 146.11 150.71 131.783 150.71 114.11H146.71ZM118.71 86.1099C134.174 86.1099 146.71 98.6459 146.71 114.11H150.71C150.71 96.4368 136.383 82.1099 118.71 82.1099V86.1099ZM118.71 82.1099C101.037 82.1099 86.71 96.4368 86.71 114.11H90.71C90.71 98.6459 103.246 86.1099 118.71 86.1099V82.1099Z"
              fill="#3AB795"
            />
          </g>
          <path
            className="line integration-line keyword-line"
            d="M228.022 122.478L269.733 65.1055L228.057 122.504"
            stroke="#FF6666"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="integration keyword-spokestack">
            <path
              d="M239.73 59.1099C239.73 42.5413 253.161 29.1099 269.73 29.1099C286.299 29.1099 299.73 42.5413 299.73 59.1099C299.73 75.6784 286.299 89.1099 269.73 89.1099C253.161 89.1099 239.73 75.6784 239.73 59.1099Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M268.301 68.4071C267.521 68.4071 266.789 68.1981 266.159 67.833C265.528 68.1981 264.796 68.4071 264.016 68.4071C261.649 68.4071 259.73 66.4859 259.73 64.116V56.9643C259.73 55.3843 261.009 54.1036 262.587 54.1036C264.165 54.1036 265.444 55.3843 265.444 56.9643V64.116C265.444 64.8415 265.714 65.504 266.159 66.0083C266.603 65.504 266.873 64.8415 266.873 64.116V54.1036C266.873 51.7337 268.792 49.8125 271.159 49.8125C271.939 49.8125 272.671 50.0215 273.301 50.3866C273.932 50.0215 274.664 49.8125 275.444 49.8125C277.811 49.8125 279.73 51.7337 279.73 54.1036V61.2553C279.73 62.8353 278.451 64.116 276.873 64.116C275.295 64.116 274.016 62.8353 274.016 61.2553V54.1036C274.016 53.3781 273.746 52.7156 273.301 52.2113C272.857 52.7156 272.587 53.3781 272.587 54.1036V64.116C272.587 66.4859 270.668 68.4071 268.301 68.4071ZM261.159 56.9643V64.116C261.159 65.696 262.438 66.9767 264.016 66.9767C264.35 66.9767 264.67 66.9193 264.968 66.8138C264.373 66.0768 264.016 65.1381 264.016 64.116L264.016 56.9643C264.016 56.1743 263.376 55.5339 262.587 55.5339C261.798 55.5339 261.159 56.1743 261.159 56.9643ZM275.444 54.1036C275.444 53.0815 275.087 52.1428 274.492 51.4058C274.79 51.3003 275.11 51.2429 275.444 51.2429C277.022 51.2429 278.301 52.5236 278.301 54.1036V61.2553C278.301 62.0453 277.662 62.6857 276.873 62.6857C276.084 62.6857 275.444 62.0453 275.444 61.2553L275.444 54.1036ZM271.159 51.2429C271.493 51.2429 271.813 51.3003 272.111 51.4058C271.515 52.1428 271.159 53.0815 271.159 54.1036V64.116C271.159 65.696 269.879 66.9767 268.301 66.9767C267.967 66.9767 267.647 66.9193 267.349 66.8138C267.944 66.0768 268.301 65.1381 268.301 64.116V54.1036C268.301 52.5236 269.581 51.2429 271.159 51.2429Z"
              fill="#61FAE9"
            />
            <path
              d="M269.73 87.1099C254.266 87.1099 241.73 74.5738 241.73 59.1099H237.73C237.73 76.783 252.057 91.1099 269.73 91.1099V87.1099ZM297.73 59.1099C297.73 74.5738 285.194 87.1099 269.73 87.1099V91.1099C287.403 91.1099 301.73 76.783 301.73 59.1099H297.73ZM269.73 31.1099C285.194 31.1099 297.73 43.6459 297.73 59.1099H301.73C301.73 41.4368 287.403 27.1099 269.73 27.1099V31.1099ZM269.73 27.1099C252.057 27.1099 237.73 41.4368 237.73 59.1099H241.73C241.73 43.6459 254.266 31.1099 269.73 31.1099V27.1099Z"
              fill="#FF6666"
            />
          </g>
          <path
            className="line integration-line nlu-line"
            d="M318.114 472.979L433 472.997L318.114 473.015M318.102 473.049L411.037 540.592L318.081 473.078M318.052 473.099L353.537 582.368L318.018 473.111M317.982 473.111L282.463 582.368L317.948 473.099M317.919 473.078L224.963 540.592L317.898 473.049M317.886 473.015L203 472.997L317.886 472.979"
            stroke="#F29E4C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="integration nlu nlu-rasa">
            <path
              d="M403 473C403 456.431 416.431 443 433 443C449.569 443 463 456.431 463 473C463 489.569 449.569 503 433 503C416.431 503 403 489.569 403 473Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M437.618 467.124V465.909H432.555V470.972H433.77V469.448H436.403V470.967H437.618V467.119V467.124ZM436.403 468.238H433.77V467.124H436.403V468.238ZM431.745 476.95H426.783V475.734H430.53V475.026H426.783V471.886H431.745V473.101H427.998V473.81H431.745V476.95ZM437.618 473.098V471.883H432.555V476.947H433.77V475.422H436.403V476.941H437.618V473.093V473.098ZM436.403 474.212H433.77V473.098H436.403V474.212Z"
              fill="white"
            />
            <path
              d="M438.732 464.797V478.032H436.633V480.288L432.837 478.19L432.54 478.026H425.672V464.797H438.735H438.732ZM440.052 463.481H424.352V479.348H432.201L437.948 482.523V479.348H440.049V463.481H440.052Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M430.115 469.045L431.671 468.478L431.745 468.449V465.911H426.783V466.013L426.772 470.975H427.987V469.819L428.898 469.489L430.265 470.975H431.766L430.115 469.045ZM427.989 468.526V467.127H430.53V467.6L427.989 468.526Z"
              fill="white"
            />
            <path
              d="M433 501C417.536 501 405 488.464 405 473H401C401 490.673 415.327 505 433 505V501ZM461 473C461 488.464 448.464 501 433 501V505C450.673 505 465 490.673 465 473H461ZM433 445C448.464 445 461 457.536 461 473H465C465 455.327 450.673 441 433 441V445ZM433 441C415.327 441 401 455.327 401 473H405C405 457.536 417.536 445 433 445V441Z"
              fill="#F29E4C"
            />
          </g>
          <g className="integration nlu nlu-amazon">
            <path
              d="M381.04 540.29C381.04 523.721 394.471 510.29 411.04 510.29H412.04C428.609 510.29 442.04 523.721 442.04 540.29C442.04 556.859 428.609 570.29 412.04 570.29H411.04C394.471 570.29 381.04 556.859 381.04 540.29Z"
              fill="black"
            />
            <path
              d="M402.553 545.49C404.281 547.47 406.599 548.89 409.181 549.55C411.499 550.01 413.919 549.77 416.074 548.81C417.152 548.33 418.128 547.65 418.962 546.81C419.043 546.69 419.002 546.53 418.88 546.45C418.779 546.41 418.636 546.41 418.535 546.45C416.949 547.25 415.241 547.81 413.492 548.11C411.642 548.31 409.771 548.19 407.982 547.73C406.152 547.13 404.403 546.27 402.817 545.17C402.695 545.13 402.573 545.19 402.553 545.31C402.532 545.39 402.532 545.45 402.553 545.49Z"
              fill="#F39700"
            />
            <path
              d="M417.213 545.91C417.904 545.77 418.616 545.75 419.328 545.81H419.348C419.572 545.87 419.714 546.09 419.673 546.33C419.531 547.01 419.307 547.67 419.023 548.29C419.002 548.35 419.043 548.41 419.104 548.43C419.145 548.45 419.165 548.43 419.206 548.41C420.06 547.75 420.548 546.75 420.548 545.67C420.568 545.39 420.385 545.15 420.1 545.11C419.063 544.93 418.006 545.15 417.111 545.71C417.071 545.75 417.05 545.81 417.091 545.87C417.132 545.91 417.172 545.93 417.213 545.91Z"
              fill="#F39700"
            />
            <path
              d="M413.634 543.21C413.268 543.61 412.841 543.97 412.374 544.29C411.499 544.79 410.503 545.07 409.486 545.09C408.592 545.07 407.717 544.77 407.006 544.25C405.806 543.17 405.338 541.53 405.826 539.99C406.274 538.65 407.311 537.57 408.653 537.07C410.117 536.63 411.642 536.41 413.187 536.41V534.83C413.085 534.11 412.496 533.55 411.764 533.47C411.296 533.41 410.849 533.47 410.422 533.65C409.812 533.93 409.385 534.47 409.242 535.13C409.141 535.33 408.917 535.45 408.693 535.41L406.497 535.21C406.294 535.15 406.152 534.99 406.131 534.77C406.294 533.69 406.863 532.75 407.697 532.07C408.815 531.23 410.198 530.77 411.621 530.79C412.923 530.81 414.204 531.21 415.281 531.97C416.176 532.71 416.664 533.81 416.603 534.95V540.41C416.623 540.87 416.745 541.33 416.949 541.73L417.64 542.71C417.721 542.87 417.721 543.03 417.64 543.19L415.607 544.89C415.424 544.99 415.22 544.97 415.058 544.87C414.529 544.37 414.041 543.81 413.634 543.21ZM413.167 538.25C412.536 538.23 411.886 538.27 411.255 538.37C410.483 538.53 409.832 539.03 409.446 539.71C409.141 540.45 409.181 541.29 409.588 542.01C410.076 542.61 410.91 542.81 411.621 542.53C412.455 542.01 413.024 541.15 413.167 540.17V538.25Z"
              fill="white"
            />
            <path
              d="M411.04 512.29H412.04V508.29H411.04V512.29ZM412.04 568.29H411.04V572.29H412.04V568.29ZM411.04 568.29C395.576 568.29 383.04 555.754 383.04 540.29H379.04C379.04 557.963 393.367 572.29 411.04 572.29V568.29ZM440.04 540.29C440.04 555.754 427.504 568.29 412.04 568.29V572.29C429.713 572.29 444.04 557.963 444.04 540.29H440.04ZM412.04 512.29C427.504 512.29 440.04 524.826 440.04 540.29H444.04C444.04 522.617 429.713 508.29 412.04 508.29V512.29ZM411.04 508.29C393.367 508.29 379.04 522.617 379.04 540.29H383.04C383.04 524.826 395.576 512.29 411.04 512.29V508.29Z"
              fill="#F29E4C"
            />
          </g>
          <g className="integration nlu nlu-dialogflow">
            <path
              d="M323.54 582.546C323.54 565.977 336.971 552.546 353.54 552.546C370.109 552.546 383.54 565.977 383.54 582.546C383.54 599.114 370.109 612.546 353.54 612.546C336.971 612.546 323.54 599.114 323.54 582.546Z"
              fill="black"
            />
            <path
              d="M360.936 576.99L353.24 581.435L345.54 576.99V585.879L349.39 588.1V592.546L360.936 585.879V576.99Z"
              fill="#EF6C00"
            />
            <path
              d="M353.24 581.435L345.54 576.99L353.24 572.546L360.936 576.99L353.24 581.435Z"
              fill="#FF9800"
            />
            <path
              d="M353.104 581.3L345.589 576.961L345.54 576.99L353.24 581.435L360.936 576.99L360.756 576.883L353.104 581.3Z"
              fill="#FF9800"
            />
            <path
              d="M353.24 581.49V581.435L345.589 577.017L345.54 577.046L353.24 581.49Z"
              fill="#FF9800"
            />
            <path
              d="M353.54 610.546C338.076 610.546 325.54 598.01 325.54 582.546H321.54C321.54 600.219 335.867 614.546 353.54 614.546V610.546ZM381.54 582.546C381.54 598.01 369.004 610.546 353.54 610.546V614.546C371.213 614.546 385.54 600.219 385.54 582.546H381.54ZM353.54 554.546C369.004 554.546 381.54 567.082 381.54 582.546H385.54C385.54 564.873 371.213 550.546 353.54 550.546V554.546ZM353.54 550.546C335.867 550.546 321.54 564.873 321.54 582.546H325.54C325.54 567.082 338.076 554.546 353.54 554.546V550.546Z"
              fill="#F29E4C"
            />
          </g>
          <g className="integration nlu nlu-spokestack">
            <path
              d="M252.46 582.546C252.46 565.977 265.891 552.546 282.46 552.546C299.029 552.546 312.46 565.977 312.46 582.546C312.46 599.114 299.029 612.546 282.46 612.546C265.891 612.546 252.46 599.114 252.46 582.546Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M281.031 591.843C280.251 591.843 279.519 591.634 278.889 591.269C278.258 591.634 277.526 591.843 276.746 591.843C274.379 591.843 272.46 589.922 272.46 587.552V580.4C272.46 578.82 273.739 577.54 275.317 577.54C276.895 577.54 278.174 578.82 278.174 580.4V587.552C278.174 588.278 278.444 588.94 278.889 589.444C279.333 588.94 279.603 588.278 279.603 587.552V577.54C279.603 575.17 281.522 573.249 283.889 573.249C284.669 573.249 285.401 573.457 286.031 573.823C286.662 573.457 287.394 573.249 288.174 573.249C290.541 573.249 292.46 575.17 292.46 577.54V584.691C292.46 586.271 291.181 587.552 289.603 587.552C288.025 587.552 286.746 586.271 286.746 584.691V577.54C286.746 576.814 286.476 576.152 286.031 575.647C285.587 576.152 285.317 576.814 285.317 577.54V587.552C285.317 589.922 283.398 591.843 281.031 591.843ZM273.889 580.4V587.552C273.889 589.132 275.168 590.413 276.746 590.413C277.08 590.413 277.4 590.355 277.698 590.25C277.103 589.513 276.746 588.574 276.746 587.552L276.746 580.4C276.746 579.61 276.106 578.97 275.317 578.97C274.528 578.97 273.889 579.61 273.889 580.4ZM288.174 577.54C288.174 576.517 287.817 575.579 287.221 574.842C287.519 574.736 287.84 574.679 288.174 574.679C289.752 574.679 291.031 575.96 291.031 577.54V584.691C291.031 585.481 290.392 586.122 289.603 586.122C288.814 586.122 288.174 585.481 288.174 584.691L288.174 577.54ZM283.889 574.679C284.223 574.679 284.543 574.736 284.841 574.842C284.245 575.579 283.889 576.517 283.889 577.54V587.552C283.889 589.132 282.609 590.413 281.031 590.413C280.697 590.413 280.377 590.355 280.079 590.25C280.674 589.513 281.031 588.574 281.031 587.552V577.54C281.031 575.96 282.311 574.679 283.889 574.679Z"
              fill="#61FAE9"
            />
            <path
              d="M282.46 610.546C266.996 610.546 254.46 598.01 254.46 582.546H250.46C250.46 600.219 264.787 614.546 282.46 614.546V610.546ZM310.46 582.546C310.46 598.01 297.924 610.546 282.46 610.546V614.546C300.133 614.546 314.46 600.219 314.46 582.546H310.46ZM282.46 554.546C297.924 554.546 310.46 567.082 310.46 582.546H314.46C314.46 564.873 300.133 550.546 282.46 550.546V554.546ZM282.46 550.546C264.787 550.546 250.46 564.873 250.46 582.546H254.46C254.46 567.082 266.996 554.546 282.46 554.546V550.546Z"
              fill="#F29E4C"
            />
          </g>
          <g className="integration nlu nlu-jovo">
            <path
              d="M194.96 540.29C194.96 523.721 208.391 510.29 224.96 510.29C241.529 510.29 254.96 523.721 254.96 540.29C254.96 556.859 241.529 570.29 224.96 570.29C208.391 570.29 194.96 556.859 194.96 540.29Z"
              fill="black"
            />
            <image
              href="/homepage/diagrams/jovo.png"
              x="214.96"
              y="530.29"
              height="20px"
              width="20px"
            />
            <path
              d="M224.96 568.29C209.496 568.29 196.96 555.754 196.96 540.29H192.96C192.96 557.963 207.287 572.29 224.96 572.29V568.29ZM252.96 540.29C252.96 555.754 240.424 568.29 224.96 568.29V572.29C242.633 572.29 256.96 557.963 256.96 540.29H252.96ZM224.96 512.29C240.424 512.29 252.96 524.826 252.96 540.29H256.96C256.96 522.617 242.633 508.29 224.96 508.29V512.29ZM224.96 508.29C207.287 508.29 192.96 522.617 192.96 540.29H196.96C196.96 524.826 209.496 512.29 224.96 512.29V508.29Z"
              fill="#F29E4C"
            />
          </g>
          <g className="integration nlu nlu-microsoft">
            <path
              d="M173 473C173 456.431 186.431 443 203 443C219.569 443 233 456.431 233 473C233 489.569 219.569 503 203 503C186.431 503 173 489.569 173 473Z"
              fill="black"
            />
            <path
              d="M193.87 463.87H202.565V472.565H193.87V463.87Z"
              fill="#F35325"
            />
            <path
              d="M203.435 463.87H212.13V472.565H203.435V463.87Z"
              fill="#81BC06"
            />
            <path
              d="M193.87 473.435H202.565V482.13H193.87V473.435Z"
              fill="#05A6F0"
            />
            <path
              d="M203.435 473.435H212.13V482.13H203.435V473.435Z"
              fill="#FFBA08"
            />
            <path
              d="M203 501C187.536 501 175 488.464 175 473H171C171 490.673 185.327 505 203 505V501ZM231 473C231 488.464 218.464 501 203 501V505C220.673 505 235 490.673 235 473H231ZM203 445C218.464 445 231 457.536 231 473H235C235 455.327 220.673 441 203 441V445ZM203 441C185.327 441 171 455.327 171 473H175C175 457.536 187.536 445 203 445V441Z"
              fill="#F29E4C"
            />
          </g>
          <path
            className="line integration-line asr-line"
            d="M170.516 365.655L102.956 458.583L170.459 365.613L61.1801 401.083L170.437 365.546L61.1801 330.009L170.459 365.478L102.956 272.509L170.516 365.436"
            stroke="#C490D1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="integration asr asr-spokestack">
            <path
              d="M72.96 272.51C72.96 255.941 86.3914 242.51 102.96 242.51C119.529 242.51 132.96 255.941 132.96 272.51C132.96 289.078 119.529 302.51 102.96 302.51C86.3914 302.51 72.96 289.078 72.96 272.51Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M101.531 281.807C100.751 281.807 100.019 281.598 99.3885 281.233C98.7582 281.598 98.0263 281.807 97.2457 281.807C94.8787 281.807 92.96 279.886 92.96 277.516V270.364C92.96 268.784 94.2391 267.503 95.8171 267.503C97.3951 267.503 98.6742 268.784 98.6742 270.364V277.516C98.6742 278.241 98.944 278.904 99.3885 279.408C99.8331 278.904 100.103 278.241 100.103 277.516V267.503C100.103 265.134 102.022 263.212 104.389 263.212C105.169 263.212 105.901 263.421 106.531 263.786C107.162 263.421 107.894 263.212 108.674 263.212C111.041 263.212 112.96 265.134 112.96 267.503V274.655C112.96 276.235 111.681 277.516 110.103 277.516C108.525 277.516 107.246 276.235 107.246 274.655V267.503C107.246 266.778 106.976 266.116 106.531 265.611C106.087 266.116 105.817 266.778 105.817 267.503V277.516C105.817 279.886 103.898 281.807 101.531 281.807ZM94.3885 270.364V277.516C94.3885 279.096 95.6677 280.377 97.2457 280.377C97.5798 280.377 97.9004 280.319 98.1984 280.214C97.6026 279.477 97.2457 278.538 97.2457 277.516L97.2457 270.364C97.2457 269.574 96.6061 268.934 95.8171 268.934C95.0281 268.934 94.3885 269.574 94.3885 270.364ZM108.674 267.503C108.674 266.481 108.317 265.543 107.721 264.806C108.019 264.7 108.34 264.643 108.674 264.643C110.252 264.643 111.531 265.924 111.531 267.503V274.655C111.531 275.445 110.892 276.086 110.103 276.086C109.314 276.086 108.674 275.445 108.674 274.655L108.674 267.503ZM104.389 264.643C104.723 264.643 105.043 264.7 105.341 264.806C104.745 265.543 104.389 266.481 104.389 267.503V277.516C104.389 279.096 103.109 280.377 101.531 280.377C101.197 280.377 100.877 280.319 100.579 280.214C101.174 279.477 101.531 278.538 101.531 277.516V267.503C101.531 265.924 102.811 264.643 104.389 264.643Z"
              fill="#61FAE9"
            />
            <path
              d="M102.96 300.51C87.496 300.51 74.96 287.974 74.96 272.51H70.96C70.96 290.183 85.2868 304.51 102.96 304.51V300.51ZM130.96 272.51C130.96 287.974 118.424 300.51 102.96 300.51V304.51C120.633 304.51 134.96 290.183 134.96 272.51H130.96ZM102.96 244.51C118.424 244.51 130.96 257.046 130.96 272.51H134.96C134.96 254.837 120.633 240.51 102.96 240.51V244.51ZM102.96 240.51C85.2868 240.51 70.96 254.837 70.96 272.51H74.96C74.96 257.046 87.496 244.51 102.96 244.51V240.51Z"
              fill="#C490D1"
            />
          </g>
          <g className="integration asr asr-android">
            <path
              d="M31.1801 330.01C31.1801 313.513 44.5534 300.01 61.0502 300.01C77.547 300.01 90.9203 313.513 90.9203 330.01C90.9203 346.507 77.547 360.01 61.0502 360.01C44.5534 360.01 31.1801 346.507 31.1801 330.01Z"
              fill="black"
            />
            <path
              d="M65.9082 327.784L67.5601 324.91C67.6533 324.749 67.5969 324.544 67.4387 324.451C67.2804 324.357 67.0745 324.411 66.9834 324.573L65.3099 327.483C64.0331 326.898 62.5958 326.571 61.0502 326.571C59.5045 326.571 58.0673 326.898 56.7904 327.483L55.1169 324.573C55.0237 324.411 54.8199 324.357 54.6595 324.451C54.4991 324.544 54.4449 324.749 54.5381 324.91L56.19 327.784C53.3415 329.334 51.4121 332.23 51.0935 335.62H71.0069C70.6882 332.23 68.7589 329.334 65.9082 327.784H65.9082ZM56.4783 332.811C56.0165 332.811 55.6437 332.434 55.6437 331.973C55.6437 331.509 56.0187 331.135 56.4783 331.135C56.94 331.135 57.3129 331.511 57.3129 331.973C57.3151 332.434 56.94 332.811 56.4783 332.811ZM65.6199 332.811C65.1582 332.811 64.7853 332.434 64.7853 331.973C64.7853 331.509 65.1603 331.135 65.6199 331.135C66.0817 331.135 66.4545 331.511 66.4545 331.973C66.4567 332.434 66.0817 332.811 65.6199 332.811Z"
              fill="#32DE84"
            />
            <path
              d="M61.0502 358.01C45.6692 358.01 33.1801 345.413 33.1801 330.01H29.1801C29.1801 347.6 43.4375 362.01 61.0502 362.01V358.01ZM88.9203 330.01C88.9203 345.413 76.4312 358.01 61.0502 358.01V362.01C78.6628 362.01 92.9203 347.6 92.9203 330.01H88.9203ZM61.0502 302.01C76.4312 302.01 88.9203 314.606 88.9203 330.01H92.9203C92.9203 312.42 78.6628 298.01 61.0502 298.01V302.01ZM61.0502 298.01C43.4375 298.01 29.1801 312.42 29.1801 330.01H33.1801C33.1801 314.606 45.6692 302.01 61.0502 302.01V298.01Z"
              fill="#C490D1"
            />
          </g>
          <g className="integration asr asr-apple">
            <path
              d="M31.1801 401.08C31.1801 384.512 44.6115 371.08 61.1801 371.08C77.7486 371.08 91.1801 384.512 91.1801 401.08C91.1801 417.649 77.7486 431.08 61.1801 431.08C44.6115 431.08 31.1801 417.649 31.1801 401.08Z"
              fill="black"
            />
            <path
              d="M69.6734 406.666C69.3709 407.365 69.0129 408.008 68.5981 408.599C68.0327 409.406 67.5697 409.964 67.213 410.273C66.6599 410.782 66.0673 411.043 65.4328 411.057C64.9772 411.057 64.4279 410.928 63.7884 410.665C63.1468 410.403 62.5572 410.273 62.0181 410.273C61.4526 410.273 60.8462 410.403 60.1976 410.665C59.548 410.928 59.0247 411.065 58.6246 411.078C58.0161 411.104 57.4096 410.836 56.8041 410.273C56.4177 409.936 55.9344 409.359 55.3554 408.54C54.7342 407.666 54.2235 406.653 53.8234 405.497C53.3949 404.249 53.1801 403.04 53.1801 401.87C53.1801 400.53 53.4697 399.374 54.0498 398.405C54.5057 397.627 55.1122 397.013 55.8713 396.563C56.6304 396.112 57.4507 395.882 58.334 395.868C58.8173 395.868 59.4511 396.017 60.2387 396.311C61.0241 396.606 61.5284 396.755 61.7495 396.755C61.9149 396.755 62.4751 396.581 63.4248 396.232C64.3229 395.909 65.0809 395.775 65.7019 395.828C67.3846 395.964 68.6487 396.627 69.4894 397.822C67.9845 398.734 67.2401 400.011 67.2549 401.649C67.2685 402.925 67.7315 403.987 68.6413 404.83C69.0536 405.222 69.5141 405.524 70.0265 405.739C69.9154 406.061 69.7981 406.37 69.6734 406.666ZM65.8142 391.48C65.8142 392.48 65.4488 393.414 64.7204 394.279C63.8415 395.306 62.7783 395.9 61.6254 395.806C61.6107 395.686 61.6021 395.56 61.6021 395.427C61.6021 394.467 62.0202 393.44 62.7625 392.599C63.1331 392.174 63.6044 391.82 64.176 391.538C64.7464 391.26 65.2859 391.106 65.7933 391.08C65.8081 391.214 65.8142 391.348 65.8142 391.48V391.48Z"
              fill="white"
            />
            <path
              d="M61.1801 429.08C45.7161 429.08 33.1801 416.544 33.1801 401.08H29.1801C29.1801 418.753 43.5069 433.08 61.1801 433.08V429.08ZM89.1801 401.08C89.1801 416.544 76.644 429.08 61.1801 429.08V433.08C78.8532 433.08 93.1801 418.753 93.1801 401.08H89.1801ZM61.1801 373.08C76.644 373.08 89.1801 385.616 89.1801 401.08H93.1801C93.1801 383.407 78.8532 369.08 61.1801 369.08V373.08ZM61.1801 369.08C43.5069 369.08 29.1801 383.407 29.1801 401.08H33.1801C33.1801 385.616 45.7161 373.08 61.1801 373.08V369.08Z"
              fill="#C490D1"
            />
          </g>
          <g className="integration asr asr-google">
            <path
              d="M72.96 458.08C72.96 441.788 86.1676 428.58 102.46 428.58H103.2C119.493 428.58 132.7 441.788 132.7 458.08C132.7 474.372 119.493 487.58 103.2 487.58H102.46C86.1676 487.58 72.96 474.372 72.96 458.08Z"
              fill="black"
            />
            <path
              d="M106.26 453.644H106.997L109.099 451.568L109.202 450.687C107.998 449.637 106.543 448.908 104.974 448.568C103.405 448.228 101.775 448.289 100.236 448.745C98.698 449.2 97.3026 450.036 96.182 451.172C95.0615 452.309 94.2527 453.708 93.8322 455.239C94.0664 455.144 94.3258 455.129 94.5697 455.196L98.7737 454.511C98.7737 454.511 98.9875 454.161 99.0982 454.183C99.9989 453.206 101.244 452.606 102.578 452.506C103.912 452.406 105.234 452.813 106.274 453.644H106.26Z"
              fill="#EA4335"
            />
            <path
              d="M112.094 455.239C111.61 453.482 110.618 451.902 109.239 450.694L106.289 453.607C106.904 454.104 107.397 454.731 107.73 455.443C108.064 456.154 108.229 456.932 108.214 457.716V458.233C108.559 458.233 108.9 458.3 109.219 458.43C109.537 458.56 109.827 458.751 110.071 458.992C110.315 459.233 110.508 459.519 110.64 459.833C110.772 460.148 110.84 460.485 110.84 460.826C110.84 461.166 110.772 461.504 110.64 461.818C110.508 462.133 110.315 462.419 110.071 462.659C109.827 462.9 109.537 463.091 109.219 463.222C108.9 463.352 108.559 463.419 108.214 463.419H102.963L102.439 463.943V467.054L102.963 467.571H108.214C109.68 467.582 111.112 467.127 112.296 466.273C113.48 465.418 114.354 464.21 114.789 462.827C115.224 461.444 115.197 459.96 114.712 458.593C114.226 457.226 113.308 456.05 112.094 455.239Z"
              fill="#4285F4"
            />
            <path
              d="M97.7042 467.542H102.955V463.39H97.7042C97.3301 463.39 96.9604 463.31 96.6201 463.157L95.8825 463.383L93.7658 465.458L93.5814 466.187C94.7684 467.072 96.2168 467.548 97.7042 467.542Z"
              fill="#34A853"
            />
            <path
              d="M97.7042 454.074C96.2814 454.082 94.8967 454.529 93.7437 455.353C92.5906 456.176 91.7267 457.334 91.2726 458.666C90.8185 459.998 90.7969 461.436 91.2109 462.781C91.6248 464.125 92.4536 465.308 93.5814 466.165L96.6274 463.157C96.2404 462.984 95.9015 462.721 95.6399 462.391C95.3783 462.06 95.2018 461.672 95.1255 461.259C95.0493 460.847 95.0755 460.422 95.202 460.021C95.3285 459.621 95.5515 459.257 95.8519 458.96C96.1522 458.664 96.521 458.443 96.9264 458.318C97.3319 458.193 97.762 458.167 98.1799 458.243C98.5977 458.318 98.9909 458.492 99.3255 458.751C99.6602 459.009 99.9263 459.344 100.101 459.726L103.147 456.718C102.508 455.892 101.684 455.224 100.74 454.766C99.7954 454.307 98.7565 454.07 97.7042 454.074Z"
              fill="#FBBC05"
            />
            <path
              d="M102.46 430.58H103.2V426.58H102.46V430.58ZM103.2 485.58H102.46V489.58H103.2V485.58ZM102.46 485.58C87.2721 485.58 74.96 473.268 74.96 458.08H70.96C70.96 475.477 85.063 489.58 102.46 489.58V485.58ZM130.7 458.08C130.7 473.268 118.388 485.58 103.2 485.58V489.58C120.597 489.58 134.7 475.477 134.7 458.08H130.7ZM103.2 430.58C118.388 430.58 130.7 442.892 130.7 458.08H134.7C134.7 440.683 120.597 426.58 103.2 426.58V430.58ZM102.46 426.58C85.063 426.58 70.96 440.683 70.96 458.08H74.96C74.96 442.892 87.2721 430.58 102.46 430.58V426.58Z"
              fill="#C490D1"
            />
          </g>
          <path
            className="line integration-line vad-spokestack-line"
            d="M465.432 365.44L574.688 400.977L465.41 365.508"
            stroke="#A4036F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="vad integration vad-spokestack">
            <path
              d="M544.69 400.98C544.69 384.411 558.121 370.98 574.69 370.98C591.258 370.98 604.69 384.411 604.69 400.98C604.69 417.549 591.258 430.98 574.69 430.98C558.121 430.98 544.69 417.549 544.69 400.98Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M573.261 410.277C572.481 410.277 571.749 410.068 571.119 409.703C570.488 410.068 569.756 410.277 568.976 410.277C566.609 410.277 564.69 408.356 564.69 405.986V398.834C564.69 397.254 565.969 395.974 567.547 395.974C569.125 395.974 570.404 397.254 570.404 398.834V405.986C570.404 406.712 570.674 407.374 571.119 407.878C571.563 407.374 571.833 406.712 571.833 405.986V395.974C571.833 393.604 573.752 391.683 576.119 391.683C576.899 391.683 577.631 391.892 578.261 392.257C578.892 391.892 579.624 391.683 580.404 391.683C582.771 391.683 584.69 393.604 584.69 395.974V403.125C584.69 404.705 583.411 405.986 581.833 405.986C580.255 405.986 578.976 404.705 578.976 403.125V395.974C578.976 395.248 578.706 394.586 578.261 394.081C577.817 394.586 577.547 395.248 577.547 395.974V405.986C577.547 408.356 575.628 410.277 573.261 410.277ZM566.119 398.834V405.986C566.119 407.566 567.398 408.847 568.976 408.847C569.31 408.847 569.63 408.789 569.928 408.684C569.333 407.947 568.976 407.008 568.976 405.986L568.976 398.834C568.976 398.044 568.336 397.404 567.547 397.404C566.758 397.404 566.119 398.044 566.119 398.834ZM580.404 395.974C580.404 394.952 580.047 394.013 579.451 393.276C579.749 393.17 580.07 393.113 580.404 393.113C581.982 393.113 583.261 394.394 583.261 395.974V403.125C583.261 403.915 582.622 404.556 581.833 404.556C581.044 404.556 580.404 403.915 580.404 403.125L580.404 395.974ZM576.119 393.113C576.453 393.113 576.773 393.17 577.071 393.276C576.475 394.013 576.119 394.952 576.119 395.974V405.986C576.119 407.566 574.839 408.847 573.261 408.847C572.927 408.847 572.607 408.789 572.309 408.684C572.904 407.947 573.261 407.008 573.261 405.986V395.974C573.261 394.394 574.541 393.113 576.119 393.113Z"
              fill="#61FAE9"
            />
            <path
              d="M574.69 428.98C559.226 428.98 546.69 416.444 546.69 400.98H542.69C542.69 418.653 557.017 432.98 574.69 432.98V428.98ZM602.69 400.98C602.69 416.444 590.154 428.98 574.69 428.98V432.98C592.363 432.98 606.69 418.653 606.69 400.98H602.69ZM574.69 372.98C590.154 372.98 602.69 385.516 602.69 400.98H606.69C606.69 383.307 592.363 368.98 574.69 368.98V372.98ZM574.69 368.98C557.017 368.98 542.69 383.307 542.69 400.98H546.69C546.69 385.516 559.226 372.98 574.69 372.98V368.98Z"
              fill="#A4036F"
            />
          </g>
          <path
            className="line integration-line tts-line"
            d="M409.093 190.613L518.372 226.083L409.115 190.546L518.372 155.009L409.093 190.478L476.595 97.5089L409.036 190.437L409 75.5459L408.964 190.437"
            stroke="#FACC5F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="integration tts tts-apple">
            <path
              d="M446.6 97.5098C446.6 80.9412 460.031 67.5098 476.6 67.5098C493.169 67.5098 506.6 80.9412 506.6 97.5098V98.5098C506.6 115.078 493.169 128.51 476.6 128.51C460.031 128.51 446.6 115.078 446.6 98.5098V97.5098Z"
              fill="black"
            />
            <image
              href="/homepage/diagrams/siri.png"
              x="466.6"
              y="87.8433"
              height="20px"
              width="20px"
            />
          </g>
          <g className="integration tts tts-amazon">
            <path
              d="M488.37 155.01C488.37 138.441 501.801 125.01 518.37 125.01C534.939 125.01 548.37 138.441 548.37 155.01V156.01C548.37 172.578 534.939 186.01 518.37 186.01C501.801 186.01 488.37 172.578 488.37 156.01V155.01Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M518.365 145.343C512.845 145.343 508.37 149.895 508.37 155.51C508.37 160.665 512.142 164.924 517.032 165.587V163.538C517.032 162.96 516.674 162.441 516.136 162.257C513.315 161.294 511.296 158.55 511.37 155.339C511.461 151.429 514.592 148.354 518.437 148.394C522.268 148.433 525.362 151.604 525.362 155.51C525.362 155.599 525.359 155.688 525.356 155.776C525.355 155.796 525.355 155.815 525.354 155.835C525.35 155.917 525.345 155.999 525.339 156.08C525.337 156.106 525.335 156.132 525.332 156.157C525.327 156.214 525.322 156.27 525.315 156.327C525.308 156.391 525.3 156.454 525.291 156.517C525.288 156.546 525.284 156.575 525.279 156.604C524.423 162.343 517.074 165.569 517.033 165.587C517.469 165.646 517.914 165.677 518.365 165.677C523.885 165.677 528.36 161.125 528.36 155.51C528.36 149.895 523.885 145.343 518.365 145.343Z"
              fill="#5FCAF4"
            />
          </g>
          <g className="integration tts tts-google">
            <path
              d="M488.37 226.08C488.37 209.512 501.801 196.08 518.37 196.08C534.939 196.08 548.37 209.512 548.37 226.08V227.08C548.37 243.649 534.939 257.08 518.37 257.08C501.801 257.08 488.37 243.649 488.37 227.08V226.08Z"
              fill="black"
            />
            <path
              d="M514.474 229.28C517.845 229.28 520.578 226.502 520.578 223.074C520.578 219.647 517.845 216.868 514.474 216.868C511.103 216.868 508.37 219.647 508.37 223.074C508.37 226.502 511.103 229.28 514.474 229.28Z"
              fill="#4285F4"
            />
            <path
              d="M528.37 223.68C528.37 224.377 527.814 224.941 527.129 224.941C526.444 224.941 525.889 224.377 525.889 223.68C525.889 222.983 526.444 222.418 527.129 222.418C527.814 222.418 528.37 222.983 528.37 223.68Z"
              fill="#34A853"
            />
            <path
              d="M526.137 226.757C526.137 228.15 525.026 229.28 523.655 229.28C522.285 229.28 521.174 228.15 521.174 226.757C521.174 225.364 522.285 224.234 523.655 224.234C525.026 224.234 526.137 225.364 526.137 226.757Z"
              fill="#EA4335"
            />
            <path
              d="M526.633 233.266C526.633 234.938 525.3 236.293 523.655 236.293C522.011 236.293 520.678 234.938 520.678 233.266C520.678 231.594 522.011 230.239 523.655 230.239C525.3 230.239 526.633 231.594 526.633 233.266Z"
              fill="#FBBC05"
            />
          </g>
          <g className="integration tts tts-spokestack">
            <path
              d="M379 75.5498C379 58.9813 392.431 45.5498 409 45.5498C425.569 45.5498 439 58.9813 439 75.5498C439 92.1183 425.569 105.55 409 105.55C392.431 105.55 379 92.1183 379 75.5498Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M407.571 84.847C406.791 84.847 406.059 84.6381 405.429 84.273C404.798 84.6381 404.066 84.847 403.286 84.847C400.919 84.847 399 82.9259 399 80.556V73.4042C399 71.8243 400.279 70.5435 401.857 70.5435C403.435 70.5435 404.714 71.8243 404.714 73.4042V80.556C404.714 81.2815 404.984 81.9439 405.429 82.4482C405.873 81.9439 406.143 81.2815 406.143 80.556V70.5435C406.143 68.1736 408.062 66.2524 410.429 66.2524C411.209 66.2524 411.941 66.4614 412.571 66.8265C413.202 66.4614 413.934 66.2524 414.714 66.2524C417.081 66.2524 419 68.1736 419 70.5435V77.6953C419 79.2752 417.721 80.556 416.143 80.556C414.565 80.556 413.286 79.2752 413.286 77.6953V70.5435C413.286 69.818 413.016 69.1556 412.571 68.6513C412.127 69.1556 411.857 69.818 411.857 70.5435V80.556C411.857 82.9259 409.938 84.847 407.571 84.847ZM400.429 73.4042V80.556C400.429 82.1359 401.708 83.4167 403.286 83.4167C403.62 83.4167 403.94 83.3593 404.238 83.2538C403.643 82.5167 403.286 81.5781 403.286 80.556L403.286 73.4042C403.286 72.6142 402.646 71.9739 401.857 71.9739C401.068 71.9739 400.429 72.6142 400.429 73.4042ZM414.714 70.5435C414.714 69.5214 414.357 68.5828 413.762 67.8457C414.06 67.7402 414.38 67.6828 414.714 67.6828C416.292 67.6828 417.571 68.9636 417.571 70.5435V77.6953C417.571 78.4852 416.932 79.1256 416.143 79.1256C415.354 79.1256 414.714 78.4852 414.714 77.6953L414.714 70.5435ZM410.429 67.6828C410.763 67.6828 411.083 67.7402 411.381 67.8457C410.785 68.5828 410.429 69.5214 410.429 70.5435V80.556C410.429 82.1359 409.149 83.4167 407.571 83.4167C407.237 83.4167 406.917 83.3593 406.619 83.2538C407.215 82.5167 407.571 81.5781 407.571 80.556V70.5435C407.571 68.9636 408.851 67.6828 410.429 67.6828Z"
              fill="#61FAE9"
            />
            <path
              d="M409 103.55C393.536 103.55 381 91.0138 381 75.5498H377C377 93.2229 391.327 107.55 409 107.55V103.55ZM437 75.5498C437 91.0138 424.464 103.55 409 103.55V107.55C426.673 107.55 441 93.2229 441 75.5498H437ZM409 47.5498C424.464 47.5498 437 60.0858 437 75.5498H441C441 57.8767 426.673 43.5498 409 43.5498V47.5498ZM409 43.5498C391.327 43.5498 377 57.8767 377 75.5498H381C381 60.0858 393.536 47.5498 409 47.5498V43.5498Z"
              fill="#FACC5F"
            />
          </g>
          <path
            className="line stack-line"
            d="M318 207.546L318.065 317.457L422.616 283.554M318 207.546L317.935 317.457L213.384 283.554M318 207.546L409.107 192.148L422.616 283.554M318 207.546L227.969 193.504M422.616 283.554L318.105 317.58L382.656 406.538M422.616 283.554L465.414 365.444L382.656 406.538M382.656 406.538L318 317.656L253.344 406.538M382.656 406.538L318 472.546L253.344 406.538M253.344 406.538L317.895 317.58L213.384 283.554M253.344 406.538L170.586 365.444L213.384 283.554M213.384 283.554L227.969 193.504M228 122.546L227.978 193.478L227.969 193.504M160.475 171.606L227.943 193.504L227.969 193.504"
            stroke="url(#paint0_linear)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="10 10"
          />
          <g className="stack wake-word-stack">
            <rect
              x="130.47"
              y="141.61"
              width="60"
              height="60"
              rx="30"
              fill="#3AB795"
            />
            <path
              d="M151.174 165.149L152.323 159.079H154.075L152.182 167.61H150.413L149.024 161.903L147.635 167.61H145.866L143.973 159.079H145.725L146.88 165.137L148.286 159.079H149.774L151.174 165.149Z"
              fill="#2C363F"
            />
            <path
              d="M159.829 165.852H156.746L156.161 167.61H154.291L157.467 159.079H159.096L162.289 167.61H160.42L159.829 165.852ZM157.221 164.428H159.354L158.282 161.235L157.221 164.428Z"
              fill="#2C363F"
            />
            <path
              d="M165.998 164.188L165.084 165.172V167.61H163.326V159.079H165.084V162.946L165.858 161.885L168.031 159.079H170.194L167.164 162.87L170.281 167.61H168.19L165.998 164.188Z"
              fill="#2C363F"
            />
            <path
              d="M176.316 163.913H172.941V166.198H176.902V167.61H171.184V159.079H176.891V160.502H172.941V162.536H176.316V163.913Z"
              fill="#2C363F"
            />
            <path
              d="M150.389 182.149L151.538 176.079H153.29L151.397 184.61H149.628L148.239 178.903L146.85 184.61H145.081L143.188 176.079H144.94L146.094 182.137L147.501 176.079H148.989L150.389 182.149Z"
              fill="#2C363F"
            />
            <path
              d="M161.493 180.538C161.493 181.377 161.344 182.114 161.047 182.747C160.75 183.379 160.325 183.868 159.77 184.211C159.219 184.555 158.586 184.727 157.871 184.727C157.164 184.727 156.534 184.557 155.979 184.217C155.424 183.877 154.995 183.393 154.69 182.764C154.385 182.131 154.231 181.405 154.227 180.584V180.163C154.227 179.323 154.377 178.584 154.678 177.948C154.983 177.307 155.411 176.817 155.961 176.477C156.516 176.133 157.149 175.961 157.86 175.961C158.571 175.961 159.202 176.133 159.752 176.477C160.307 176.817 160.735 177.307 161.036 177.948C161.34 178.584 161.493 179.321 161.493 180.157V180.538ZM159.711 180.151C159.711 179.256 159.551 178.577 159.231 178.112C158.911 177.647 158.454 177.415 157.86 177.415C157.27 177.415 156.815 177.645 156.495 178.106C156.174 178.563 156.012 179.235 156.008 180.122V180.538C156.008 181.409 156.168 182.084 156.489 182.565C156.809 183.045 157.27 183.286 157.871 183.286C158.461 183.286 158.914 183.055 159.231 182.594C159.547 182.129 159.707 181.454 159.711 180.567V180.151Z"
              fill="#2C363F"
            />
            <path
              d="M166.168 181.487H164.768V184.61H163.01V176.079H166.18C167.188 176.079 167.965 176.303 168.512 176.752C169.059 177.202 169.332 177.836 169.332 178.657C169.332 179.239 169.205 179.725 168.951 180.116C168.701 180.502 168.32 180.811 167.809 181.042L169.654 184.528V184.61H167.768L166.168 181.487ZM164.768 180.063H166.186C166.627 180.063 166.969 179.952 167.211 179.729C167.453 179.502 167.574 179.192 167.574 178.797C167.574 178.395 167.459 178.079 167.229 177.848C167.002 177.618 166.652 177.502 166.18 177.502H164.768V180.063Z"
              fill="#2C363F"
            />
            <path
              d="M170.914 184.61V176.079H173.539C174.289 176.079 174.959 176.249 175.549 176.588C176.142 176.924 176.605 177.405 176.937 178.03C177.269 178.651 177.435 179.358 177.435 180.151V180.543C177.435 181.336 177.271 182.042 176.943 182.659C176.619 183.276 176.16 183.754 175.566 184.094C174.973 184.434 174.303 184.606 173.557 184.61H170.914ZM172.672 177.502V183.198H173.521C174.209 183.198 174.734 182.973 175.098 182.524C175.461 182.075 175.646 181.432 175.654 180.596V180.145C175.654 179.278 175.475 178.622 175.115 178.176C174.756 177.727 174.23 177.502 173.539 177.502H172.672Z"
              fill="#2C363F"
            />
          </g>
          <g className="stack keyword-stack">
            <rect
              x="198"
              y="92.5498"
              width="60"
              height="60"
              rx="30"
              fill="#FF6666"
            />
            <path
              d="M208.981 123.483L208.295 124.222V126.05H206.977V119.651H208.295V122.552L208.876 121.756L210.506 119.651H212.128L209.856 122.495L212.193 126.05H210.625L208.981 123.483Z"
              fill="#2C363F"
            />
            <path
              d="M216.72 123.277H214.188V124.991H217.159V126.05H212.87V119.651H217.15V120.719H214.188V122.244H216.72V123.277Z"
              fill="#2C363F"
            />
            <path
              d="M220.319 122.534L221.65 119.651H223.092L220.991 123.729V126.05H219.651V123.729L217.55 119.651H218.996L220.319 122.534Z"
              fill="#2C363F"
            />
            <path
              d="M228.918 124.204L229.78 119.651H231.094L229.674 126.05H228.347L227.306 121.77L226.264 126.05H224.937L223.518 119.651H224.832L225.697 124.195L226.752 119.651H227.868L228.918 124.204Z"
              fill="#2C363F"
            />
            <path
              d="M237.246 122.996C237.246 123.625 237.135 124.178 236.912 124.652C236.689 125.127 236.37 125.493 235.954 125.751C235.541 126.009 235.066 126.138 234.53 126.138C234 126.138 233.527 126.01 233.111 125.755C232.695 125.5 232.372 125.137 232.144 124.666C231.915 124.191 231.8 123.646 231.797 123.031V122.714C231.797 122.084 231.91 121.531 232.135 121.053C232.364 120.573 232.684 120.205 233.098 119.95C233.514 119.692 233.988 119.563 234.521 119.563C235.055 119.563 235.528 119.692 235.941 119.95C236.357 120.205 236.678 120.573 236.903 121.053C237.132 121.531 237.246 122.083 237.246 122.71V122.996ZM235.91 122.706C235.91 122.035 235.79 121.525 235.55 121.176C235.309 120.828 234.967 120.653 234.521 120.653C234.079 120.653 233.738 120.826 233.497 121.172C233.257 121.515 233.136 122.019 233.133 122.684V122.996C233.133 123.649 233.253 124.156 233.493 124.516C233.733 124.876 234.079 125.057 234.53 125.057C234.973 125.057 235.312 124.884 235.55 124.538C235.787 124.189 235.907 123.683 235.91 123.018V122.706Z"
              fill="#2C363F"
            />
            <path
              d="M240.753 123.708H239.702V126.05H238.384V119.651H240.761C241.517 119.651 242.1 119.82 242.51 120.157C242.921 120.494 243.126 120.97 243.126 121.585C243.126 122.021 243.03 122.386 242.84 122.679C242.653 122.969 242.367 123.201 241.983 123.374L243.367 125.988V126.05H241.952L240.753 123.708ZM239.702 122.64H240.766C241.097 122.64 241.353 122.556 241.535 122.389C241.716 122.219 241.807 121.986 241.807 121.69C241.807 121.389 241.721 121.151 241.548 120.979C241.378 120.806 241.116 120.719 240.761 120.719H239.702V122.64Z"
              fill="#2C363F"
            />
            <path
              d="M244.312 126.05V119.651H246.281C246.843 119.651 247.346 119.779 247.788 120.034C248.233 120.286 248.581 120.646 248.83 121.115C249.079 121.581 249.203 122.111 249.203 122.706V123C249.203 123.595 249.08 124.124 248.834 124.586C248.591 125.049 248.247 125.408 247.801 125.663C247.356 125.918 246.854 126.047 246.294 126.05H244.312ZM245.63 120.719V124.991H246.268C246.783 124.991 247.177 124.822 247.45 124.485C247.722 124.148 247.861 123.667 247.867 123.04V122.701C247.867 122.051 247.732 121.559 247.463 121.225C247.193 120.888 246.799 120.719 246.281 120.719H245.63Z"
              fill="#2C363F"
            />
          </g>
          <g className="stack nlu-stack">
            <rect
              x="288"
              y="442.546"
              width="60"
              height="60"
              rx="30"
              fill="#F29E4C"
            />
            <path
              d="M312.138 479.046H309.501L304.369 470.626V479.046H301.732V466.249H304.369L309.51 474.687V466.249H312.138V479.046Z"
              fill="#2C363F"
            />
            <path
              d="M317.438 476.928H323.036V479.046H314.801V466.249H317.438V476.928Z"
              fill="#2C363F"
            />
            <path
              d="M334.391 466.249V474.678C334.391 476.078 333.952 477.186 333.073 478C332.2 478.814 331.005 479.222 329.487 479.222C327.993 479.222 326.806 478.826 325.927 478.035C325.048 477.244 324.6 476.157 324.583 474.774V466.249H327.219V474.695C327.219 475.533 327.419 476.146 327.817 476.532C328.221 476.913 328.778 477.104 329.487 477.104C330.969 477.104 331.722 476.324 331.746 474.766V466.249H334.391Z"
              fill="#2C363F"
            />
          </g>
          <g className="stack asr-stack">
            <rect
              x="140.59"
              y="335.44"
              width="60"
              height="60"
              rx="30"
              fill="#C490D1"
            />
            <path
              d="M161.256 369.303H156.633L155.754 371.94H152.951L157.714 359.143H160.158L164.948 371.94H162.144L161.256 369.303ZM157.345 367.167H160.544L158.936 362.377L157.345 367.167Z"
              fill="#2C363F"
            />
            <path
              d="M173.139 368.583C173.139 368.084 172.963 367.704 172.611 367.44C172.26 367.17 171.627 366.889 170.713 366.596C169.799 366.297 169.075 366.004 168.542 365.717C167.089 364.932 166.362 363.875 166.362 362.544C166.362 361.853 166.556 361.238 166.943 360.699C167.335 360.154 167.895 359.729 168.621 359.424C169.354 359.12 170.174 358.967 171.082 358.967C171.996 358.967 172.811 359.134 173.526 359.468C174.24 359.796 174.794 360.262 175.187 360.866C175.585 361.469 175.784 362.155 175.784 362.922H173.148C173.148 362.336 172.963 361.882 172.594 361.56C172.225 361.232 171.706 361.068 171.038 361.068C170.394 361.068 169.893 361.206 169.535 361.481C169.178 361.75 168.999 362.108 168.999 362.553C168.999 362.969 169.207 363.318 169.623 363.599C170.045 363.88 170.663 364.144 171.478 364.39C172.978 364.841 174.07 365.401 174.756 366.069C175.442 366.737 175.784 367.569 175.784 368.565C175.784 369.672 175.365 370.542 174.527 371.175C173.69 371.802 172.562 372.116 171.144 372.116C170.159 372.116 169.263 371.937 168.454 371.58C167.646 371.216 167.027 370.721 166.6 370.094C166.178 369.467 165.967 368.741 165.967 367.915H168.612C168.612 369.327 169.456 370.033 171.144 370.033C171.771 370.033 172.26 369.907 172.611 369.655C172.963 369.397 173.139 369.04 173.139 368.583Z"
              fill="#2C363F"
            />
            <path
              d="M182.675 367.255H180.574V371.94H177.937V359.143H182.692C184.204 359.143 185.37 359.48 186.19 360.154C187.011 360.828 187.421 361.78 187.421 363.01C187.421 363.883 187.23 364.613 186.849 365.199C186.474 365.779 185.903 366.242 185.136 366.587L187.904 371.817V371.94H185.074L182.675 367.255ZM180.574 365.12H182.701C183.363 365.12 183.876 364.953 184.239 364.619C184.602 364.279 184.784 363.813 184.784 363.221C184.784 362.618 184.611 362.143 184.265 361.797C183.926 361.452 183.401 361.279 182.692 361.279H180.574V365.12Z"
              fill="#2C363F"
            />
          </g>
          <g className="stack vad-stack">
            <rect
              x="435.41"
              y="335.44"
              width="60"
              height="60"
              rx="30"
              fill="#A4036F"
            />
            <path
              d="M453.457 368.767L456.358 359.143H459.293L454.837 371.94H452.086L447.648 359.143H450.574L453.457 368.767Z"
              fill="white"
            />
            <path
              d="M467.423 369.303H462.8L461.921 371.94H459.117L463.881 359.143H466.324L471.114 371.94H468.31L467.423 369.303ZM463.512 367.167H466.711L465.102 362.377L463.512 367.167Z"
              fill="white"
            />
            <path
              d="M472.669 371.94V359.143H476.607C477.732 359.143 478.737 359.398 479.622 359.908C480.512 360.412 481.207 361.132 481.705 362.07C482.203 363.001 482.452 364.062 482.452 365.251V365.84C482.452 367.03 482.206 368.087 481.713 369.013C481.227 369.939 480.539 370.657 479.648 371.167C478.757 371.676 477.752 371.934 476.633 371.94H472.669ZM475.306 361.279V369.822H476.581C477.612 369.822 478.4 369.485 478.945 368.811C479.49 368.137 479.768 367.173 479.78 365.919V365.243C479.78 363.942 479.51 362.958 478.971 362.29C478.432 361.616 477.644 361.279 476.607 361.279H475.306Z"
              fill="white"
            />
          </g>
          <g className="stack tts-stack">
            <rect
              x="379.11"
              y="162.15"
              width="60"
              height="60"
              rx="30"
              fill="#FACC5F"
            />
            <path
              d="M402.844 187.989H398.924V198.65H396.287V187.989H392.42V185.853H402.844V187.989Z"
              fill="#2C363F"
            />
            <path
              d="M414.489 187.989H410.569V198.65H407.932V187.989H404.065V185.853H414.489V187.989Z"
              fill="#2C363F"
            />
            <path
              d="M422.856 195.292C422.856 194.794 422.68 194.414 422.328 194.15C421.977 193.88 421.344 193.599 420.43 193.306C419.516 193.007 418.792 192.714 418.259 192.427C416.806 191.642 416.079 190.584 416.079 189.254C416.079 188.563 416.273 187.948 416.659 187.409C417.052 186.864 417.612 186.439 418.338 186.134C419.071 185.83 419.891 185.677 420.799 185.677C421.713 185.677 422.528 185.844 423.242 186.178C423.957 186.506 424.511 186.972 424.904 187.576C425.302 188.179 425.501 188.865 425.501 189.632H422.865C422.865 189.046 422.68 188.592 422.311 188.27C421.942 187.942 421.423 187.778 420.755 187.778C420.111 187.778 419.61 187.916 419.252 188.191C418.895 188.46 418.716 188.818 418.716 189.263C418.716 189.679 418.924 190.028 419.34 190.309C419.762 190.59 420.38 190.854 421.195 191.1C422.695 191.551 423.787 192.111 424.473 192.779C425.158 193.447 425.501 194.279 425.501 195.275C425.501 196.382 425.082 197.252 424.244 197.885C423.407 198.512 422.279 198.826 420.861 198.826C419.876 198.826 418.98 198.647 418.171 198.29C417.363 197.926 416.744 197.431 416.317 196.804C415.895 196.177 415.684 195.451 415.684 194.625H418.329C418.329 196.037 419.173 196.743 420.861 196.743C421.488 196.743 421.977 196.617 422.328 196.365C422.68 196.107 422.856 195.75 422.856 195.292Z"
              fill="#2C363F"
            />
          </g>
          <path
            className="line platform-line"
            d="M318 207.546L318.065 317.457L422.616 283.554L318.105 317.58L382.656 406.538L318 317.656L253.344 406.538L317.895 317.58L213.384 283.554L317.935 317.457L318 207.546Z"
            stroke="url(#paint1_linear)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDashoffset="15"
            strokeDasharray="10 10"
          />
          <g className="platform platform-python">
            <path
              d="M223.34 406.99C223.34 390.422 236.771 376.99 253.34 376.99H254.34C270.909 376.99 284.34 390.422 284.34 406.99C284.34 423.559 270.909 436.99 254.34 436.99H253.34C236.771 436.99 223.34 423.559 223.34 406.99Z"
              fill="black"
            />
            <g clipPath="url(#clip0)">
              <path
                d="M253.354 397.102C252.543 397.106 251.77 397.174 251.089 397.292C249.083 397.641 248.719 398.37 248.719 399.716V401.493H253.459V402.085H248.719H246.94C245.562 402.085 244.356 402.899 243.979 404.449C243.544 406.225 243.524 407.333 243.979 409.187C244.316 410.567 245.12 411.551 246.498 411.551H248.128V409.421C248.128 407.882 249.481 406.524 251.089 406.524H255.823C257.141 406.524 258.193 405.457 258.193 404.155V399.716C258.193 398.452 257.109 397.503 255.823 397.292C255.009 397.159 254.164 397.099 253.354 397.102ZM250.79 398.531C251.28 398.531 251.68 398.931 251.68 399.422C251.68 399.912 251.28 400.308 250.79 400.308C250.299 400.308 249.901 399.912 249.901 399.422C249.901 398.931 250.299 398.531 250.79 398.531Z"
                fill="url(#paint2_linear)"
              />
              <path
                d="M258.784 402.085V404.155C258.784 405.76 257.401 407.111 255.823 407.111H251.089C249.792 407.111 248.719 408.203 248.719 409.48V413.92C248.719 415.183 249.836 415.927 251.089 416.289C252.589 416.723 254.028 416.801 255.823 416.289C257.016 415.949 258.193 415.265 258.193 413.92V412.143H253.459V411.551H258.193H260.563C261.941 411.551 262.454 410.606 262.933 409.187C263.428 407.727 263.407 406.322 262.933 404.449C262.593 403.1 261.942 402.085 260.563 402.085H258.784ZM256.121 413.328C256.613 413.328 257.011 413.724 257.011 414.213C257.011 414.705 256.613 415.104 256.121 415.104C255.632 415.104 255.232 414.705 255.232 414.213C255.232 413.724 255.632 413.328 256.121 413.328Z"
                fill="url(#paint3_linear)"
              />
            </g>
            <path
              d="M253.34 378.99H254.34V374.99H253.34V378.99ZM254.34 434.99H253.34V438.99H254.34V434.99ZM253.34 434.99C237.876 434.99 225.34 422.454 225.34 406.99H221.34C221.34 424.663 235.667 438.99 253.34 438.99V434.99ZM282.34 406.99C282.34 422.454 269.804 434.99 254.34 434.99V438.99C272.013 438.99 286.34 424.663 286.34 406.99H282.34ZM254.34 378.99C269.804 378.99 282.34 391.526 282.34 406.99H286.34C286.34 389.317 272.013 374.99 254.34 374.99V378.99ZM253.34 374.99C235.667 374.99 221.34 389.317 221.34 406.99H225.34C225.34 391.526 237.876 378.99 253.34 378.99V374.99Z"
              fill="url(#paint4_linear)"
            />
          </g>
          <g className="platform platform-node">
            <path
              d="M183.38 283.51C183.38 267.217 196.588 254.01 212.88 254.01C229.172 254.01 242.38 267.217 242.38 283.51V285.51C242.38 301.802 229.172 315.01 212.88 315.01C196.588 315.01 183.38 301.802 183.38 285.51V283.51Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M212.21 274.473C212.495 274.3 212.852 274.3 213.14 274.473L220.851 279.072C221.139 279.245 221.316 279.563 221.316 279.906V289.114C221.316 289.457 221.139 289.775 220.851 289.947L213.14 294.546C212.852 294.72 212.495 294.72 212.21 294.546L204.504 289.947C204.21 289.775 204.03 289.457 204.03 289.114V279.906C204.03 279.563 204.201 279.245 204.495 279.072L212.21 274.473Z"
              fill="url(#paint5_linear)"
            />
            <path
              d="M240.38 283.51V285.51H244.38V283.51H240.38ZM185.38 285.51V283.51H181.38V285.51H185.38ZM212.88 313.01C197.692 313.01 185.38 300.698 185.38 285.51H181.38C181.38 302.907 195.483 317.01 212.88 317.01V313.01ZM240.38 285.51C240.38 300.698 228.068 313.01 212.88 313.01V317.01C230.277 317.01 244.38 302.907 244.38 285.51H240.38ZM212.88 256.01C228.068 256.01 240.38 268.322 240.38 283.51H244.38C244.38 266.113 230.277 252.01 212.88 252.01V256.01ZM212.88 252.01C195.483 252.01 181.38 266.113 181.38 283.51H185.38C185.38 268.322 197.692 256.01 212.88 256.01V252.01Z"
              fill="url(#paint6_linear)"
            />
          </g>
          <g className="platform platform-ios">
            <path
              d="M288 207.546C288 190.977 301.431 177.546 318 177.546C334.569 177.546 348 190.977 348 207.546C348 224.114 334.569 237.546 318 237.546C301.431 237.546 288 224.114 288 207.546Z"
              fill="black"
            />
            <path
              d="M326.493 213.132C326.191 213.831 325.833 214.474 325.418 215.065C324.853 215.871 324.39 216.429 324.033 216.739C323.48 217.248 322.887 217.508 322.253 217.523C321.797 217.523 321.248 217.394 320.608 217.131C319.967 216.869 319.377 216.739 318.838 216.739C318.273 216.739 317.666 216.869 317.018 217.131C316.368 217.394 315.845 217.531 315.445 217.544C314.836 217.57 314.23 217.302 313.624 216.739C313.238 216.402 312.754 215.824 312.175 215.006C311.554 214.132 311.043 213.118 310.643 211.963C310.215 210.715 310 209.506 310 208.336C310 206.996 310.29 205.84 310.87 204.871C311.326 204.093 311.932 203.479 312.691 203.028C313.45 202.578 314.271 202.348 315.154 202.334C315.637 202.334 316.271 202.483 317.059 202.777C317.844 203.072 318.348 203.221 318.569 203.221C318.735 203.221 319.295 203.046 320.245 202.698C321.143 202.375 321.901 202.241 322.522 202.294C324.205 202.429 325.469 203.093 326.309 204.288C324.804 205.199 324.06 206.477 324.075 208.115C324.088 209.391 324.551 210.453 325.461 211.296C325.874 211.688 326.334 211.99 326.846 212.205C326.735 212.527 326.618 212.836 326.493 213.132ZM322.634 197.946C322.634 198.946 322.269 199.88 321.54 200.745C320.661 201.772 319.598 202.366 318.445 202.272C318.431 202.152 318.422 202.026 318.422 201.893C318.422 200.933 318.84 199.905 319.582 199.065C319.953 198.64 320.424 198.286 320.996 198.004C321.566 197.726 322.106 197.572 322.613 197.546C322.628 197.68 322.634 197.813 322.634 197.946V197.946Z"
              fill="white"
            />
            <path
              d="M318 235.546C302.536 235.546 290 223.01 290 207.546H286C286 225.219 300.327 239.546 318 239.546V235.546ZM346 207.546C346 223.01 333.464 235.546 318 235.546V239.546C335.673 239.546 350 225.219 350 207.546H346ZM318 179.546C333.464 179.546 346 192.082 346 207.546H350C350 189.873 335.673 175.546 318 175.546V179.546ZM318 175.546C300.327 175.546 286 189.873 286 207.546H290C290 192.082 302.536 179.546 318 179.546V175.546Z"
              fill="url(#paint7_linear)"
            />
          </g>
          <g className="platform platform-android">
            <path
              d="M392.62 284.01C392.62 267.441 406.051 254.01 422.62 254.01C439.189 254.01 452.62 267.441 452.62 284.01C452.62 300.578 439.189 314.01 422.62 314.01C406.051 314.01 392.62 300.578 392.62 284.01Z"
              fill="black"
            />
            <path
              d="M427.499 281.784L429.158 278.91C429.252 278.749 429.195 278.544 429.036 278.451C428.877 278.357 428.67 278.411 428.579 278.573L426.898 281.483C425.616 280.898 424.172 280.571 422.62 280.571C421.068 280.571 419.624 280.898 418.342 281.483L416.661 278.573C416.567 278.411 416.363 278.357 416.202 278.451C416.04 278.544 415.986 278.749 416.08 278.91L417.739 281.784C414.878 283.334 412.94 286.23 412.62 289.62H432.62C432.3 286.23 430.362 283.334 427.499 281.784H427.499ZM418.028 286.811C417.564 286.811 417.19 286.434 417.19 285.973C417.19 285.509 417.567 285.135 418.028 285.135C418.492 285.135 418.866 285.511 418.866 285.973C418.869 286.434 418.492 286.811 418.028 286.811ZM427.21 286.811C426.746 286.811 426.371 286.434 426.371 285.973C426.371 285.509 426.748 285.135 427.21 285.135C427.673 285.135 428.048 285.511 428.048 285.973C428.05 286.434 427.673 286.811 427.21 286.811Z"
              fill="#32DE84"
            />
            <path
              d="M422.62 312.01C407.156 312.01 394.62 299.474 394.62 284.01H390.62C390.62 301.683 404.947 316.01 422.62 316.01V312.01ZM450.62 284.01C450.62 299.474 438.084 312.01 422.62 312.01V316.01C440.293 316.01 454.62 301.683 454.62 284.01H450.62ZM422.62 256.01C438.084 256.01 450.62 268.546 450.62 284.01H454.62C454.62 266.337 440.293 252.01 422.62 252.01V256.01ZM422.62 252.01C404.947 252.01 390.62 266.337 390.62 284.01H394.62C394.62 268.546 407.156 256.01 422.62 256.01V252.01Z"
              fill="url(#paint8_linear)"
            />
          </g>
          <g className="platform platform-rn">
            <path
              d="M352.66 406.99C352.66 390.422 366.091 376.99 382.66 376.99H383.66C400.229 376.99 413.66 390.422 413.66 406.99C413.66 423.559 400.229 436.99 383.66 436.99H382.66C366.091 436.99 352.66 423.559 352.66 406.99Z"
              fill="black"
            />
            <path
              d="M382.652 409.272C383.803 409.272 384.736 408.354 384.736 407.222C384.736 406.09 383.803 405.172 382.652 405.172C381.501 405.172 380.568 406.09 380.568 407.222C380.568 408.354 381.501 409.272 382.652 409.272Z"
              fill="#61DAFB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M372.688 405.958C372.185 406.405 371.977 406.833 371.977 407.222C371.977 407.611 372.185 408.039 372.688 408.486C373.191 408.932 373.947 409.357 374.925 409.725C376.878 410.458 379.609 410.922 382.652 410.922C385.694 410.922 388.426 410.458 390.378 409.725C391.356 409.357 392.113 408.932 392.616 408.486C393.119 408.039 393.327 407.611 393.327 407.222C393.327 406.833 393.119 406.405 392.616 405.958C392.113 405.512 391.356 405.086 390.378 404.719C388.426 403.986 385.694 403.522 382.652 403.522C379.609 403.522 376.878 403.986 374.925 404.719C373.947 405.086 373.191 405.512 372.688 405.958ZM374.563 403.785C376.658 402.998 379.518 402.522 382.652 402.522C385.786 402.522 388.646 402.998 390.741 403.785C391.787 404.178 392.668 404.658 393.297 405.216C393.926 405.774 394.343 406.451 394.343 407.222C394.343 407.993 393.926 408.67 393.297 409.228C392.668 409.786 391.787 410.266 390.741 410.659C388.646 411.446 385.786 411.922 382.652 411.922C379.518 411.922 376.658 411.446 374.563 410.659C373.517 410.266 372.635 409.786 372.007 409.228C371.378 408.67 370.96 407.993 370.96 407.222C370.96 406.451 371.378 405.774 372.007 405.216C372.635 404.658 373.517 404.178 374.563 403.785Z"
              fill="#61DAFB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M378.782 398.103C378.138 397.897 377.657 397.934 377.314 398.129C376.972 398.323 376.699 398.714 376.557 399.366C376.415 400.018 376.419 400.875 376.585 401.892C376.916 403.921 377.873 406.48 379.394 409.072C380.915 411.664 382.69 413.758 384.311 415.055C385.124 415.704 385.876 416.136 386.521 416.341C387.166 416.546 387.647 416.51 387.989 416.315C388.332 416.121 388.604 415.729 388.746 415.078C388.888 414.426 388.884 413.569 388.718 412.552C388.388 410.522 387.431 407.964 385.909 405.372C384.388 402.78 382.614 400.685 380.992 399.389C380.18 398.739 379.427 398.308 378.782 398.103ZM381.633 398.613C383.374 400.004 385.223 402.202 386.79 404.872C388.357 407.541 389.368 410.216 389.722 412.394C389.899 413.481 389.918 414.472 389.74 415.287C389.563 416.101 389.176 416.796 388.498 417.181C387.819 417.566 387.014 417.549 386.208 417.293C385.402 417.036 384.539 416.525 383.67 415.831C381.93 414.44 380.08 412.241 378.514 409.572C376.947 406.902 375.936 404.228 375.581 402.05C375.404 400.963 375.386 399.972 375.563 399.157C375.74 398.342 376.127 397.648 376.806 397.263C377.484 396.877 378.289 396.895 379.095 397.151C379.901 397.408 380.764 397.918 381.633 398.613Z"
              fill="#61DAFB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M388.746 399.366C388.604 398.714 388.332 398.323 387.989 398.129C387.647 397.934 387.166 397.897 386.521 398.103C385.876 398.308 385.124 398.739 384.311 399.389C382.69 400.685 380.915 402.78 379.394 405.372C377.873 407.964 376.916 410.522 376.585 412.552C376.419 413.569 376.415 414.426 376.557 415.078C376.699 415.729 376.972 416.121 377.314 416.315C377.657 416.51 378.138 416.546 378.782 416.341C379.427 416.136 380.18 415.704 380.992 415.055C382.614 413.758 384.388 411.664 385.909 409.072C387.431 406.48 388.388 403.921 388.718 401.892C388.884 400.875 388.888 400.018 388.746 399.366ZM389.722 402.05C389.368 404.228 388.357 406.902 386.79 409.572C385.223 412.241 383.374 414.44 381.633 415.831C380.764 416.525 379.901 417.036 379.095 417.293C378.289 417.549 377.484 417.566 376.806 417.181C376.127 416.796 375.74 416.101 375.563 415.287C375.386 414.472 375.404 413.481 375.581 412.394C375.936 410.216 376.947 407.541 378.514 404.872C380.08 402.202 381.93 400.004 383.67 398.613C384.539 397.918 385.402 397.408 386.208 397.151C387.014 396.895 387.819 396.877 388.498 397.263C389.176 397.648 389.563 398.342 389.74 399.157C389.918 399.972 389.899 400.963 389.722 402.05Z"
              fill="#61DAFB"
            />
            <path
              d="M382.66 378.99H383.66V374.99H382.66V378.99ZM383.66 434.99H382.66V438.99H383.66V434.99ZM382.66 434.99C367.196 434.99 354.66 422.454 354.66 406.99H350.66C350.66 424.663 364.987 438.99 382.66 438.99V434.99ZM411.66 406.99C411.66 422.454 399.124 434.99 383.66 434.99V438.99C401.333 438.99 415.66 424.663 415.66 406.99H411.66ZM383.66 378.99C399.124 378.99 411.66 391.526 411.66 406.99H415.66C415.66 389.317 401.333 374.99 383.66 374.99V378.99ZM382.66 374.99C364.987 374.99 350.66 389.317 350.66 406.99H354.66C354.66 391.526 367.196 378.99 382.66 378.99V374.99Z"
              fill="url(#paint9_linear)"
            />
          </g>
          <g className="spokestack-main">
            <path
              d="M258 317.546C258 284.409 284.863 257.546 318 257.546C351.137 257.546 378 284.409 378 317.546C378 350.683 351.137 377.546 318 377.546C284.863 377.546 258 350.683 258 317.546Z"
              fill="#2F5BEA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M315.143 336.141C313.582 336.141 312.118 335.723 310.857 334.993C309.596 335.723 308.133 336.141 306.571 336.141C301.838 336.141 298 332.299 298 327.559V313.255C298 310.095 300.558 307.534 303.714 307.534C306.87 307.534 309.429 310.095 309.429 313.255V327.559C309.429 329.01 309.968 330.335 310.857 331.343C311.746 330.335 312.286 329.01 312.286 327.559V307.534C312.286 302.794 316.123 298.952 320.857 298.952C322.418 298.952 323.882 299.37 325.143 300.1C326.404 299.37 327.867 298.952 329.429 298.952C334.162 298.952 338 302.794 338 307.534V321.837C338 324.997 335.442 327.559 332.286 327.559C329.13 327.559 326.571 324.997 326.571 321.837V307.534C326.571 306.083 326.032 304.758 325.143 303.749C324.254 304.758 323.714 306.083 323.714 307.534V327.559C323.714 332.299 319.877 336.141 315.143 336.141ZM300.857 313.255V327.559C300.857 330.719 303.416 333.28 306.571 333.28C307.24 333.28 307.881 333.165 308.477 332.954C307.285 331.48 306.571 329.603 306.571 327.559L306.571 313.255C306.571 311.675 305.292 310.394 303.714 310.394C302.136 310.394 300.857 311.675 300.857 313.255ZM329.429 307.534C329.429 305.49 328.715 303.612 327.523 302.138C328.119 301.927 328.76 301.812 329.429 301.812C332.584 301.812 335.143 304.374 335.143 307.534V321.837C335.143 323.417 333.864 324.698 332.286 324.698C330.708 324.698 329.429 323.417 329.429 321.837L329.429 307.534ZM320.857 301.812C321.525 301.812 322.167 301.927 322.763 302.138C321.571 303.612 320.857 305.49 320.857 307.534V327.559C320.857 330.719 318.299 333.28 315.143 333.28C314.475 333.28 313.833 333.165 313.237 332.954C314.429 331.48 315.143 329.603 315.143 327.559V307.534C315.143 304.374 317.701 301.812 320.857 301.812Z"
              fill="#61FAE9"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="160.475"
              y1="297.546"
              x2="465.414"
              y2="297.546"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="208"
              y1="317.546"
              x2="428"
              y2="317.546"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear"
              x1="243.645"
              y1="397.102"
              x2="254.422"
              y2="406.439"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5A9FD4" />
              <stop offset="1" stopColor="#306998" />
            </linearGradient>
            <linearGradient
              id="paint3_linear"
              x1="256.007"
              y1="414.064"
              x2="252.172"
              y2="408.599"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFD43B" />
              <stop offset="1" stopColor="#FFE873" />
            </linearGradient>
            <linearGradient
              id="paint4_linear"
              x1="223.34"
              y1="406.99"
              x2="284.34"
              y2="406.99"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <linearGradient
              id="paint5_linear"
              x1="211.655"
              y1="293.336"
              x2="214.792"
              y2="275.876"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.57" stopColor="#3E863D" />
              <stop offset="0.72" stopColor="#619857" />
              <stop offset="1" stopColor="#76AC64" />
            </linearGradient>
            <linearGradient
              id="paint6_linear"
              x1="183.38"
              y1="284.51"
              x2="242.38"
              y2="284.51"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <linearGradient
              id="paint7_linear"
              x1="288"
              y1="207.546"
              x2="348"
              y2="207.546"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <linearGradient
              id="paint8_linear"
              x1="392.62"
              y1="284.01"
              x2="452.62"
              y2="284.01"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <linearGradient
              id="paint9_linear"
              x1="352.66"
              y1="406.99"
              x2="413.66"
              y2="406.99"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#61FAE9" />
              <stop offset="1" stopColor="#2F5BEA" />
            </linearGradient>
            <clipPath id="clip0">
              <rect
                width="21.35"
                height="20"
                fill="white"
                transform="translate(242.657 396.99)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  )
}

const styles = {
  scroller: css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 65px 50px;
    display: none;

    ${theme.MIN_LARGE_DISPLAY_MEDIA_QUERY} {
      display: block;
    }

    ${theme.ieBreakpointMinDefault} {
      display: none;
    }
  `,
  content: css`
    position: sticky;
    top: 50px;
    max-width: 1440px;
    margin: 0 auto;
  `,
  svg: css`
    width: 636px;
    max-width: 100%;
    fill: none;

    & > g,
    .line,
    .cloud-background {
      transform-origin: 50% 50%;
      transition: all 0.5s ${theme.transitionEasing};
      opacity: 0;
    }

    .spokestack-main {
      transform: scale(0.5);
    }

    .cloud-background {
      transform: skew(10deg, 5deg);
    }

    .line {
      stroke-dashoffset: 15;
    }

    &.modular,
    &.platforms,
    &.full-stack,
    &.integrations,
    &.custom {
      .cloud-background,
      .spokestack-main {
        opacity: 1;
        transform: none;
      }

      .line {
        transition-duration: 0.75s;
      }
    }

    &.platforms,
    &.full-stack,
    &.integrations,
    &.custom {
      .platform,
      .platform-line {
        opacity: 1;
      }
      .platform-line {
        stroke-dashoffset: unset;
      }
    }

    &.full-stack,
    &.integrations,
    &.custom {
      .stack,
      .stack-line {
        opacity: 1;
      }
      .stack-line {
        stroke-dashoffset: unset;
      }
    }

    &.integrations {
      .integration,
      .integration-line {
        opacity: 1;
      }
      .integration-line {
        stroke-dashoffset: unset;
      }
    }

    &.custom {
      .custom,
      .custom-line {
        opacity: 1;
      }
      .custom-line {
        stroke-dashoffset: unset;
      }
    }

    // Speed up lines that are disappearing
    &.modular,
    &.platforms,
    &.full-stack,
    &.custom {
      .integration-line {
        transition-duration: 0.25s;
      }
    }
    &.modular,
    &.platforms {
      .stack-line {
        transition-duration: 0.25s;
      }
    }
    &.modular {
      .platform-line {
        transition-duration: 0.25s;
      }
    }
    &.integrations {
      .custom-line {
        transition-duration: 0.25s;
      }
    }
  `
}
